import React, { useEffect, useState } from 'react';
import 'views/Admin/Rsvp/FormBuilder/form-builder.css'
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, DropdownDivider, Modal, Nav, Row, Spinner, Table } from 'react-bootstrap';
import { batch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setHeaderTitle } from 'redux/interfaceManger';
import { EmailTemplate, EventDataField, RsvpForm, RsvpFormComponent, RsvpFormPageSettings, RsvpFormPageStyles } from 'types';
import { apiGetRsvpFormDetails, apiGetRsvpFormPageComponents, apiGetRsvpFormPageSettings, apiGetRsvpForms, apiSaveRsvpFormPageSettings } from 'api/rsvpForms';
import { setEditEvent } from 'redux/eventSlice';
import { BASE_URL, EmailTemplateStatuses, GuestStatus } from 'constant';
import TemplatePlaceholderPopup from 'views/Admin/EmailTemplate/TemplatePlaceholderPopup';

import { 
	DndContext, 
	DragEndEvent,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	arrayMove,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import FormArea from './FormArea';
import SidebarComponent from './SidebarComponent';

// import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { apiGetEventDataFields } from 'api/events';
import ThemeSidebar from './ThemeSidebar';
import PreviewAsPopup from './PreviewAsPopup';
import RsvpLinkPopup from './RsvpLinkPopup';
import FormSettingsPopup from './FormSettingsPopup';

let getPageComponentAbortController: null | AbortController = null;
let getPageSettingsAbortController: null | AbortController = null;
let pageSettingsTimeoutId: NodeJS.Timeout;


const FormBuilder = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { formId } = useParams();
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
		  	coordinateGetter: sortableKeyboardCoordinates,
		})
	);
	const [form, setForm] = useState<RsvpForm | null>(null);
	const [activePage, setActivePage] = useState<string>('FORM_PAGE');
	const [eventDataFields, setEventDataFields] = useState<EventDataField[]>([]);
	const [placeholderPopupOpen, setPlaceholderPopupOpen] = useState<boolean>(false);
	const [previewAsPopupOpen, setPreviewAsPopupOpen] = useState<boolean>(false);
	const [settingsPopupOpen, setSettingsPopupOpen] = useState<boolean>(false);
	const [rsvpLinksPopupOpen, setRsvpLinksPopupOpen] = useState<boolean>(false);

	const [availableComponents, setAvailableComponents] = useState<any>([
		{
			id: 'HEADING',
			name: 'Heading',
			group: 'DISPLAY',
			icon: (
				<svg width="20px" height="20px" viewBox="0 -2 20 20" xmlns="http://www.w3.org/2000/svg">
					<g id="heading-1" transform="translate(-2 -4)">
						<path id="primary" d="M19,19V9l-2,1" fill="none" stroke="#687589" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
						<path id="primary-2" data-name="primary" d="M17,19h4M4,5V19M5,5H3M5,19H3M12,5V19M11,5h2M11,19h2M4,12h8" fill="none" stroke="#687589" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					</g>
				</svg>
			),
		},
		{
			id: 'PARAGRAPH',
			name: 'Paragraph',
			group: 'DISPLAY',
			icon: (
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="24" height="24" fill="transparent"/>
					<path 
						fill-rule="evenodd" 
						clip-rule="evenodd" 
						d="M9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H9ZM9 14C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16H15C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14H9ZM12.482 1.99989C13.1608 1.99885 13.7632 1.99793 14.3196 2.2284C14.876 2.45887 15.3014 2.88551 15.7806 3.36624C16.7302 4.31875 17.6813 5.26983 18.6338 6.21942C19.1145 6.69867 19.5412 7.12401 19.7716 7.68041C20.0021 8.23682 20.0012 8.83926 20.0001 9.51807C19.9963 12.034 20 14.5499 20 17.0659C20.0001 17.9524 20.0001 18.7162 19.9179 19.3278C19.8297 19.9833 19.631 20.6117 19.1213 21.1214C18.6117 21.631 17.9833 21.8298 17.3278 21.9179C16.7161 22.0001 15.9523 22.0001 15.0658 22H8.9342C8.0477 22.0001 7.28388 22.0001 6.67221 21.9179C6.0167 21.8298 5.38835 21.631 4.87868 21.1214C4.36902 20.6117 4.17028 19.9833 4.08215 19.3278C3.99991 18.7162 3.99995 17.9524 4 17.0659L4.00001 7.00004C4.00001 6.97802 4 6.95607 4 6.93421C3.99995 6.04772 3.99991 5.28391 4.08215 4.67224C4.17028 4.01673 4.36902 3.38838 4.87869 2.87872C5.38835 2.36905 6.0167 2.17031 6.67221 2.08218C7.28387 1.99994 8.04769 1.99998 8.93418 2.00003C10.1168 2.0001 11.2994 2.00171 12.482 1.99989Z" 
						fill="#687589"
					/>
				</svg>
			),
		},
		{
			id: 'IMAGE',
			name: 'Image',
			group: 'DISPLAY',
			icon: (
				<svg width="20px" height="20px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path 
						fillRule="evenodd" clipRule="evenodd" 
						d="M1 1H15V15H1V1ZM6 9L8 11L13 6V13H3V12L6 9ZM6.5 7C7.32843 7 8 6.32843 8 5.5C8 4.67157 7.32843 4 6.5 4C5.67157 4 5 4.67157 5 5.5C5 6.32843 5.67157 7 6.5 7Z" 
						fill="#687589"
					/>
				</svg>
			),
		},
		{
			id: 'BUTTON',
			name: 'Button',
			group: 'DISPLAY',
			icon: (
				<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path fill="#687589" d="M20.5 17h-17A2.502 2.502 0 0 1 1 14.5v-4A2.502 2.502 0 0 1 3.5 8h17a2.502 2.502 0 0 1 2.5 2.5v4a2.502 2.502 0 0 1-2.5 2.5zm-17-8A1.502 1.502 0 0 0 2 10.5v4A1.502 1.502 0 0 0 3.5 16h17a1.502 1.502 0 0 0 1.5-1.5v-4A1.502 1.502 0 0 0 20.5 9zM17 12H7v1h10z"/>
					<path fill="none" d="M0 0h24v24H0z"/>
				</svg>
			),
		},
		{
			id: 'SPACER',
			name: 'Spacer',
			group: 'LAYOUT',
			icon: null,
		},
		{
			id: 'DIVIDER',
			name: 'Divider',
			group: 'LAYOUT',
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" fill="#687589" width="20px" height="20px" viewBox="0 0 24 24"><path d="M23 13H2v-2h21v2z"/></svg>
			),
		},

		// SPACER
		// DIVIDER - <hr />
		// BUTTON
		// LIST
		// HTML
		// ROWS
	])
	const [formComponents, setFormComponents] = useState<RsvpFormComponent[]>([])


	// Theme and Page settings
	const [openThemeSidebar, setOpenThemeSidebar] = useState<boolean>(false);
	const [pageStyles, setPageStyles] = useState<RsvpFormPageStyles>({
		body: {
			background: '#ffffff',
		},
		container: {
			maxWidth: 600,
			background: '#f8fafc',
			padding: 10,
			margin: '0',
			marginLeft: 'auto',
			marginRight: 'auto',
			display: 'flex',
			flexWrap: 'wrap',
			borderRadius: 0,
		},
		fieldLabels: {
			color: '#000000',
			fontFamily: 'Arial',
			fontSize: '16px'
		}
	})
	


	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('RSVP Forms & Pages'))
		})
	}, [])

	useEffect(() => {
		(async () => {
			if (activeEvent) {
				const responseFields = await apiGetEventDataFields(activeEvent.id);
				if (responseFields.success) {
					setEventDataFields([...responseFields.data.filter(f => f.show_in_app === 1)]);

					setAvailableComponents([...availableComponents, ...responseFields.data.filter(f => f.show_in_app === 1 && !['unique_guest_id', 'status'].includes(f.field)).map(field => {
						return {
							id: field.id,
							name: field.display_name,
							group: 'DATA_FIELD',
							icon: (
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5.8 5.8H5.807M5.8 14.2H5.807M5.24 8.6H14.76C15.5441 8.6 15.9361 8.6 16.2356 8.44741C16.499 8.31319 16.7132 8.09901 16.8474 7.83559C17 7.53611 17 7.14407 17 6.36V5.24C17 4.45593 17 4.06389 16.8474 3.76441C16.7132 3.50099 16.499 3.28681 16.2356 3.15259C15.9361 3 15.5441 3 14.76 3H5.24C4.45593 3 4.06389 3 3.76441 3.15259C3.50099 3.28681 3.28681 3.50099 3.15259 3.76441C3 4.06389 3 4.45593 3 5.24V6.36C3 7.14407 3 7.53611 3.15259 7.83559C3.28681 8.09901 3.50099 8.31319 3.76441 8.44741C4.06389 8.6 4.45593 8.6 5.24 8.6ZM5.24 17H14.76C15.5441 17 15.9361 17 16.2356 16.8474C16.499 16.7132 16.7132 16.499 16.8474 16.2356C17 15.9361 17 15.5441 17 14.76V13.64C17 12.8559 17 12.4639 16.8474 12.1644C16.7132 11.901 16.499 11.6868 16.2356 11.5526C15.9361 11.4 15.5441 11.4 14.76 11.4H5.24C4.45593 11.4 4.06389 11.4 3.76441 11.5526C3.50099 11.6868 3.28681 11.901 3.15259 12.1644C3 12.4639 3 12.8559 3 13.64V14.76C3 15.5441 3 15.9361 3.15259 16.2356C3.28681 16.499 3.50099 16.7132 3.76441 16.8474C4.06389 17 4.45593 17 5.24 17Z"
									stroke="#687589" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							),
							field: field.field,
						}
					})])
				}
			}
		})();
	}, [activeEvent])

	useEffect(() => {
		(async () => {
			if (activeEvent && formId) {
				const response = await apiGetRsvpFormDetails(activeEvent.id, formId)
	
				if (response.success) {
					setForm(response.data)
				}
			}
		})();
	}, [activeEvent, formId])

	useEffect(() => {
		(async () => {
			setFormComponents([]);
			console.log('=====Load data for page=====', activePage)
			// load new page components then assign in setFormComponents
			if (activeEvent && formId && activePage) {

				if (getPageComponentAbortController) {
					getPageComponentAbortController.abort();
					getPageComponentAbortController = null;
				}
				getPageComponentAbortController = new AbortController();
				const response = await apiGetRsvpFormPageComponents(activeEvent.id, formId, activePage, getPageComponentAbortController)
	
				if (response.success && response.data) {
					setFormComponents(response.data)
				}

				// -------------------
				if (getPageSettingsAbortController) {
					getPageSettingsAbortController.abort();
					getPageSettingsAbortController = null;
				}
				getPageSettingsAbortController = new AbortController();
				const responseSettings = await apiGetRsvpFormPageSettings(activeEvent.id, formId, activePage, getPageSettingsAbortController)
	
				if (responseSettings.success && responseSettings.data) {
					setPageStyles(responseSettings.data.styles)
				}
			}
		})();
	}, [activePage])

	// auto save page settings
	useEffect(() => {
		(async () => {
			const pageSettings: RsvpFormPageSettings = {
				styles: {...pageStyles}
			}
			
			// load new page components then assign in setFormComponents
			if (activeEvent && formId && activePage) {
				// Set a timeout to run the API call after 5 seconds
				pageSettingsTimeoutId = setTimeout(async () => {
					// console.log('Saving settings for page... ', activePage, pageSettings);
					const response = await apiSaveRsvpFormPageSettings(activeEvent.id, formId, activePage, pageSettings);
	
					if (response.success) {
						
					}
				}, 5000);
			}
		})();
		
		// Cleanup function to clear the timeout if useEffect runs again
		return () => clearTimeout(pageSettingsTimeoutId);
	}, [pageStyles, activePage])

	const handleDragEnd = (event: DragEndEvent) => {
		const {active, over} = event;

		// console.log('dragend', active, over)
		if (!over) {
			return;
		}
		
		

		const newComp = availableComponents.find((c: any) => c.id === active.id);

		if (newComp) {
			if (newComp.group === 'DATA_FIELD') {
				const field = eventDataFields.find(f => f.id == newComp.id);
				if (field) {
					setFormComponents([...formComponents, {
						// id: Math.random().toString(),
						id: Date.now().toString(),
						type: newComp.group,
						data: '',
						options: {
							guest_field_id: field.id,
							field: field.field,
							display_name: field.display_name,
							org_display_name: field.display_name,
							type: field.type,
							options: (field.field === 'status') ? [
								{ value: GuestStatus.CONFIRMED, label: 'Confirmed' },
								{ value: GuestStatus.UN_CONFIRMED, label: 'Unconfirmed' },
								{ value: GuestStatus.CANCELLED, label: 'Cancelled' },
							] : field.options,
							placeholder: '',
							description: '',
							isRequired: false,
							isLocked: false,
							halfWidth: false,
							allSidePadding: true,
							allSideMargin: false,
						},
						styles: null,
					}])
				}
			} else {
				setFormComponents([...formComponents, {
					// id: Math.random().toString(),
					id: Date.now().toString(),
					type: newComp.id,
					data: '',
					styles: null,
					options: null,
				}])
			}
		}

		else if (active.id !== over.id) {
			// console.log('sort')
			setFormComponents((items) => {
				const oldIndex = items.findIndex(item => item.id === active.id);
				const newIndex = items.findIndex(item => item.id === over.id);
				
				return arrayMove(items, oldIndex, newIndex);
			});
			return
		}
	}

	const updateComponent = (component: RsvpFormComponent) => {
		if (component) {
			setFormComponents([...formComponents.map((c: RsvpFormComponent) => {
				if (c.id === component.id) {
					return {
						...c,
						...component,
					}
				}

				return c
			})])
		}
	}

	const duplicateComponent = (component: RsvpFormComponent) => {
		if (component) {
			const index = formComponents.findIndex(c => c.id === component.id);
			if (index !== -1) {
				const newComponent = { ...component, id: Date.now().toString() }; // Create a new component with a new ID
				const newComponents = [...formComponents];
				newComponents.splice(index + 1, 0, newComponent); // Insert the new component at the next index
				setFormComponents(newComponents);
			}
		}
	}

	const deleteComponent = (component: RsvpFormComponent) => {
		if (component) {
			setFormComponents([...formComponents.filter(c => c.id !== component.id)])
		}
	}

	// console.log(formComponents)

	if (!form || !activeEvent) {
		return (
			<div className="pt-5 text-center">
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
		)
	}
	return (
		<>
		<div className='rsvp-form-builder'>
			<div className="page-card card">
				<div className="card-header bg-white p-3 pb-0">
					<Row className='mb-3'>
						<Col>
							<Link to={`/rsvp`} className='btn btn-white me-2'><span className='ms-3 me-3'>Back</span></Link>

							<Button variant="white"
								onClick={() => setSettingsPopupOpen(true)}
							>
								<div className="d-flex align-items-center">
									<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" 
											d="M12.7848 0.449982C13.8239 0.449982 14.7167 1.16546 14.9122 2.15495L14.9991 2.59495C15.3408 4.32442 17.1859 5.35722 18.9016 4.7794L19.3383 4.63233C20.3199 4.30175 21.4054 4.69358 21.9249 5.56605L22.7097 6.88386C23.2293 7.75636 23.0365 8.86366 22.2504 9.52253L21.9008 9.81555C20.5267 10.9672 20.5267 13.0328 21.9008 14.1844L22.2504 14.4774C23.0365 15.1363 23.2293 16.2436 22.7097 17.1161L21.925 18.4339C21.4054 19.3064 20.3199 19.6982 19.3382 19.3676L18.9017 19.2205C17.1859 18.6426 15.3408 19.6754 14.9991 21.405L14.9122 21.845C14.7167 22.8345 13.8239 23.55 12.7848 23.55H11.2152C10.1761 23.55 9.28331 22.8345 9.08781 21.8451L9.00082 21.4048C8.65909 19.6754 6.81395 18.6426 5.09822 19.2205L4.66179 19.3675C3.68016 19.6982 2.59465 19.3063 2.07505 18.4338L1.2903 17.1161C0.770719 16.2436 0.963446 15.1363 1.74956 14.4774L2.09922 14.1844C3.47324 13.0327 3.47324 10.9672 2.09922 9.8156L1.74956 9.52254C0.963446 8.86366 0.77072 7.75638 1.2903 6.8839L2.07508 5.56608C2.59466 4.69359 3.68014 4.30176 4.66176 4.63236L5.09831 4.77939C6.81401 5.35722 8.65909 4.32449 9.00082 2.59506L9.0878 2.15487C9.28331 1.16542 10.176 0.449982 11.2152 0.449982H12.7848ZM12 15.3C13.8225 15.3 15.3 13.8225 15.3 12C15.3 10.1774 13.8225 8.69998 12 8.69998C10.1774 8.69998 8.69997 10.1774 8.69997 12C8.69997 13.8225 10.1774 15.3 12 15.3Z" 
											fill="#333333"
										/>
									</svg>
									<span className='ms-2'>Settings</span>
								</div>
							</Button>
						</Col>
						<Col className='text-end'>
							<Button variant="white" className='me-2'
								onClick={() => setRsvpLinksPopupOpen(true)}
							>
								<div className="d-flex align-items-center">
									<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path 
											fill-rule="evenodd" 
											clip-rule="evenodd" 
											d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z" 
											fill="#333333"
										/>
									</svg>
									<span className='ms-2'>RSVP Links</span>
								</div>
							</Button>

							<Button variant="dark" 
								onClick={() => setPreviewAsPopupOpen(true)}
							>
								<span className='ms-3 me-3'>Preview as</span>
							</Button>
						</Col>
					</Row>

					<Row className='align-items-center'>
						<Col sm={3}>
							<p className='m-0 small text-muted'>Form name</p>
							<h5>{form.name}</h5>
						</Col>
						<Col sm={6}>
							<p className='m-0 small text-muted'>Registration Flow</p>
							<div>
								<ButtonGroup className='form-page-buttons'>
									<Button variant="" active={activePage === 'FORM_PAGE'} onClick={() => setActivePage('FORM_PAGE')}>
										<svg width="20px" height="20px" className='me-1' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5Z" stroke={(activePage === 'FORM_PAGE') ? '#000000' : '#777777'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z" stroke={(activePage === 'FORM_PAGE') ? '#000000' : '#777777'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19Z" stroke={(activePage === 'FORM_PAGE') ? '#000000' : '#777777'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
										Form Page
									</Button>
									<Button variant="" active={activePage === 'SUCCESS'} onClick={() => setActivePage('SUCCESS')}>
										<svg width="20px" height="20px" className='me-1' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path 
												fill-rule="evenodd" 
												clip-rule="evenodd" 
												d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" 
												fill={(activePage === 'SUCCESS') ? '#000000' : '#777777'}
											/>
										</svg>
										Success Page
									</Button>
									{/* <Button variant="" active={false}>Confirmation</Button> */}
								</ButtonGroup>
							</div>
						</Col>
						<Col sm={3} className='text-end'>
							<Button 
								type='button' 
								variant='link' 
								className='text-dark'
								onClick={() => setPlaceholderPopupOpen(true)}
							>View Placeholder</Button>
						</Col>
					</Row>
				</div>

				<DndContext 
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
				>
					<div className='d-flex justify-content-between align-items-stretch'>
						{(openThemeSidebar) ? (
							<ThemeSidebar 
								activeEvent={activeEvent}
								formId={formId}
								activePage={activePage}
								pageStyles={pageStyles}
								setPageStyles={setPageStyles}
								setOpenThemeSidebar={setOpenThemeSidebar}
							/>
						) : (
							<div className='form-sidebar p-3'>
								<h4 className='form-sidebar-heading'>Display</h4>
	
								<div className="form-sidebar-components mb-3">
									{availableComponents.filter((c:any) => c.group === 'DISPLAY').map((component: any, i: number) => {
										return (
											<SidebarComponent key={i} component={component} formComponents={formComponents} />
										)
									})}
								</div>
	
								{(activePage === 'FORM_PAGE') ? (
									<>
										<h4 className='form-sidebar-heading'>Data Fields</h4>
			
										<div className="form-sidebar-components data-field-components mb-3">
											{availableComponents.filter((c:any) => c.group === 'DATA_FIELD').map((component: any, i: number) => {
												return (
													<SidebarComponent key={i} component={component} formComponents={formComponents} />
												)
											})}
										</div>
									</>
								) : null}
	
								<h4 className='form-sidebar-heading'>Layout</h4>
	
								<div className="form-sidebar-components mb-3">
									{availableComponents.filter((c:any) => c.group === 'LAYOUT').map((component: any, i: number) => {
										return (
											<SidebarComponent key={i} component={component} formComponents={formComponents} />
										)
									})}
								</div>
							</div>
						)}

						<FormArea 
							activeEvent={activeEvent}
							formId={formId}
							activePage={activePage}
							components={formComponents} 
							pageStyles={pageStyles}
							updateComponent={updateComponent} 
							duplicateComponent={duplicateComponent}
							deleteComponent={deleteComponent}
							setOpenThemeSidebar={setOpenThemeSidebar}
						/>
					</div>
				</DndContext>
			</div>
		</div>

		{(placeholderPopupOpen) && (
			<TemplatePlaceholderPopup eventId={activeEvent.id} isOpen={placeholderPopupOpen} setIsOpen={setPlaceholderPopupOpen} />
		)}

		{(previewAsPopupOpen && form) && (
			<PreviewAsPopup eventId={activeEvent.id} listId={form.guest_list_id} form={form} activePage={activePage} isOpen={previewAsPopupOpen} setIsOpen={setPreviewAsPopupOpen} />
		)}

		{(rsvpLinksPopupOpen && form) && (
			<RsvpLinkPopup eventId={activeEvent.id} form={form} isOpen={rsvpLinksPopupOpen} setIsOpen={setRsvpLinksPopupOpen} />
		)}

		{(settingsPopupOpen && form) && (
			<FormSettingsPopup eventId={activeEvent.id} form={form} isOpen={settingsPopupOpen} setIsOpen={setSettingsPopupOpen} onUpdate={(data) => {
				setForm({
					...form,
					...data
				})
			}} />
		)}
		</>
	)
}

export default FormBuilder;