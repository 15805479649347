import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toast } from 'react-toastify';
import { batch } from 'react-redux';
import { unsetActiveEvent } from 'redux/eventSlice';
import { setHeaderTitle } from 'redux/interfaceManger';
import { Domain, Event, Team, TeamMember, TeamUpdateUserData } from 'types';
import { apiDeleteTeamMember, apiGetTeam, apiResendInviteTeam, apiUpdateTeamMember } from 'api/team';
import { apiGetEvent } from 'api/events';
import { EventStatus, TeamAccess, TeamRole } from 'constant';
import Select from 'react-select'
import { Link, useNavigate } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';
import { apiCreateDomain, apiDeleteDomain, apiGetDomains } from 'api/domain';
import DomainEmails from './components/DomainEmails';

const DomainPage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [domains, setDomains] = useState<Domain[]>([]);
	const [viewEmails, setViewEmails] = useState<number | null>(null);
	const [createPopupOpen, setCreatePopupOpen] = useState<boolean>(false);
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [newDomainName, setNewDomainName] = useState<string>('');

	const [deletingDomain, setDeletingDomain] = useState<Domain | null>(null);
	const [deletingErrMsg, setDeletingErrMsg] = useState<string>('');
	const [isDeleting, setIsDeleting] = useState(false);
	const [reloadTable, setReloadTable] = useState<number>(0);

	const pathname = window.location.pathname;

	useEffect(() => {
		batch(() => {
			dispatch(unsetActiveEvent())
			dispatch(setHeaderTitle('Domains'))
		})

		if (pathname === '/domain-sender-success') {
			toast.success(`Email verified successfully`)
		}
		else if (pathname === '/domain-sender-error') {
			toast.error(`Email verification failed`)
		}
	}, [])

	useEffect(() => {
		(async () => {
			const response = await apiGetDomains()
				
			if (response.success) {
				setDomains(response.data)
			}
		})();
	}, [reloadTable])


	const handleSubmit = async () => {
		if (newDomainName.trim() === '') {
			return
		}
		setIsLoading(true)
		setErrMsg('')

		const response = await apiCreateDomain(newDomainName.trim())
		setIsLoading(false)
		if (response.success) {
			if (response.data.authenticated === 1 && response.data.verified === 1) {
				setDomains([...domains, response.data])
				setCreatePopupOpen(false)
			} else {
				navigate('/domain/configuration', {state: response.data})
			}
		} else {
			setErrMsg(response.message)
		}
	}

	const handleDeleteDomain = async () => {
		console.log(deletingDomain)
		
		if (deletingDomain) {
			batch(() => {
				setDeletingErrMsg('')
				setIsDeleting(true)
			})
			const response = await apiDeleteDomain(deletingDomain.id)
			setIsDeleting(false)
			if (response.success) {
				setDeletingDomain(null);
				setReloadTable(prev => prev + 1);
			} else {
				setDeletingErrMsg(response.message)
			}
		}

	}


	return (
		<>
		<div className="cp_tabs_contentbx">
			<div className="">
				<Row>
					<Col>
						<Button type='button' variant='dark' onClick={() => setCreatePopupOpen(true)}>
							<i className="ico">
								<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7 1V13M1 7H13" stroke="#FFFFFF" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</i>
							Add a domain
						</Button>
					</Col>
				</Row>
			</div>

			<div className="">
				<table className='table  mt-5' id='domain-table'>
					<tbody>
						{domains.length === 0 && (
							<tr>
								<td colSpan={3} className='text-center text-muted'>You have not added any domains yet!</td>
							</tr>
						)}
						{domains.map((domain, i) => {
							return (
								<>
									<tr key={i}>
										<td className='text-center'>
											{(domain.authenticated === 1 && domain.verified === 1) ? (
											<span className={`domain-avatar authenticated`}>
												<svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M9.5 12.4L10.9286 14L14.5 10" stroke="#198754" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167C21 10.8996 21 11.4234 21 11.9914C21 14.4963 20.1632 16.4284 19 17.9041M3.19284 14C4.05026 18.2984 7.57641 20.5129 9.89856 21.5273C10.62 21.8424 10.9807 22 12 22C13.0193 22 13.38 21.8424 14.1014 21.5273C14.6796 21.2747 15.3324 20.9478 16 20.5328" stroke="#198754" stroke-width="1.5" stroke-linecap="round"/>
												</svg>
											</span>
											) : (
											<span className={`domain-avatar`}>
												<svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M14.5 9.5L9.50002 14.5M9.5 9.49998L14.5 14.5" stroke="#dc3545" stroke-width="1.5" stroke-linecap="round"/>
													<path d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167C21 10.8996 21 11.4234 21 11.9914C21 14.4963 20.1632 16.4284 19 17.9041M3.19284 14C4.05026 18.2984 7.57641 20.5129 9.89856 21.5273C10.62 21.8424 10.9807 22 12 22C13.0193 22 13.38 21.8424 14.1014 21.5273C14.6796 21.2747 15.3324 20.9478 16 20.5328" stroke="#dc3545" stroke-width="1.5" stroke-linecap="round"/>
												</svg>
											</span>
											)}
										</td>
										<td>
											<h5>{domain.domain}</h5>
											<p className='text-muted m-0'>{(domain.authenticated === 1 && domain.verified === 1) ? 'Authenticated' : 'Not authenticated'}</p>
										</td>
										<td style={{width: '60%'}}>
											{(domain.authenticated === 1 && domain.verified === 1) ? (
												<>
												<Button type='button' variant='link' 
													className='text-dark'
													onClick={() => {
														if (viewEmails === domain.id) {
															setViewEmails(null)
														} else {
															setViewEmails(domain.id)
														}
													}}
												>{(viewEmails === domain.id) ? 'Hide' : 'View'} Emails</Button>
												<Link to={`/domain/configuration`} state={domain} className='btn btn-link text-dark'>View Configuration</Link>
												</>
											) : (
												<>
												<Link to={`/domain/configuration`} state={domain} className='btn btn-link text-dark'>Authenticate</Link>
												</>
											)}
											
											<Button type='button' variant='link' className='text-dark' onClick={() => setDeletingDomain(domain)}>Delete</Button>
										</td>
									</tr>

									{(viewEmails === domain.id) ? (
										<tr key={`${i}_${domain.id}`}>
											<td></td>
											<td></td>
											<td style={{width: '60%'}}>
												<DomainEmails domain={domain} />
											</td>
										</tr>
									) : null}
								</>
							)
						})}
						
						
					</tbody>
				</table>
			</div>
		</div>


		{(createPopupOpen) && (
			<Modal show={createPopupOpen} className="createNewDomainModal" onHide={() => setCreatePopupOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='m-0'>Add Domain</h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{errMsg && <div className="alert alert-danger">{errMsg}</div>}
					<Form.Group className="mb-3" controlId="domainCreateModal">
						<Form.Label>Domain name</Form.Label>
						<Form.Control type="text" placeholder="" value={newDomainName} onChange={e => setNewDomainName(e.target.value)} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setCreatePopupOpen(false)} style={{ width: 'auto', flexGrow: 1, padding: '0.6rem' }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleSubmit} style={{ width: 'auto', flexGrow: 1.8, padding: '0.6rem' }} disabled={isLoading}>
						<div className='d-flex align-items-center justify-content-between'>
							<span>Add New Domain</span>
							<span className="arrow">
								<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
		)}


		{deletingDomain && (
			<Modal show={true} className="createNewAccountModal" onHide={() => setDeletingDomain(null)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>Delete Domain</span>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{deletingErrMsg && <div className="alert alert-danger">{deletingErrMsg}</div>}
					<Form onSubmit={e => e.preventDefault()}>
						<div>
							<p>
								Are you sure you want to delete [<strong>{deletingDomain.domain}</strong>] domain with all of its emails?
							</p>

							<h4 className="mb-5">
								This cannot be undone
							</h4>
						</div>

						<div className={`actionBtns`}>
							<Button type='button' variant="primary" className={`btn-steps cp_delete_btn`} disabled={isDeleting} onClick={handleDeleteDomain}>
								<span className={`step3 d-flex align-items-center justify-content-center`}>
									Yes delete domain
								</span>
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		)}


		</>
	)
}


export default DomainPage;