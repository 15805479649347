import React, { useEffect, useRef, useState } from 'react';
import { RsvpFormComponent } from 'types';
import { Button, ButtonGroup, Form, Image, InputGroup, Modal, Overlay, Tooltip } from 'react-bootstrap';
import { apiUploadRsvpFormImage } from 'api/rsvpForms';

type PropsTypes = {
	styles: React.CSSProperties
	options: any
	onUpdate: (styles: React.CSSProperties, options: any) => void
}

const BorderRadius = ({
	styles,
	options,
	onUpdate,
}: PropsTypes) => {

	const handleBorderRadius = (radius = 'ALL', type = 'PLUS') => {
		let s = {
			borderTopLeftRadius: styles.borderTopLeftRadius,
			borderTopRightRadius: styles.borderTopRightRadius,
			borderBottomLeftRadius: styles.borderBottomLeftRadius,
			borderBottomRightRadius: styles.borderBottomRightRadius,
		}
		if (radius === 'ALL') {
			let v = styles.borderTopLeftRadius as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.borderTopLeftRadius = v;
			s.borderTopRightRadius = v;
			s.borderBottomLeftRadius = v;
			s.borderBottomRightRadius = v;
		}
		else if (radius === 'TOP_LEFT') {
			let v = styles.borderTopLeftRadius as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.borderTopLeftRadius = v;
		}
		else if (radius === 'TOP_RIGHT') {
			let v = styles.borderTopRightRadius as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.borderTopRightRadius = v;
		}
		else if (radius === 'BOTTOM_LEFT') {
			let v = styles.borderBottomLeftRadius as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.borderBottomLeftRadius = v;
		}
		else if (radius === 'BOTTOM_RIGHT') {
			let v = styles.borderBottomRightRadius as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.borderBottomRightRadius = v;
		}

		onUpdate(s, options)
	}
	
	return (
		<>
			<Form.Group className='d-flex justify-content-between align-items-center'>
				<Form.Label className='m-0'>Border Radius</Form.Label>
				<Form.Check
					type="switch"
					id="border-radius-switch"
					label="More Options"
					checked={!options.allSideRadius}
					onChange={e => onUpdate(styles, {allSideRadius: !options.allSideRadius})}
				/>
			</Form.Group>
			{(options.allSideRadius) ? (
				<Form.Group>
					<Form.Label className='m-0'>All Sides</Form.Label>
					<InputGroup>
						<Button variant="outline-secondary" onClick={() => handleBorderRadius('ALL', 'MINUS')}>-</Button>
						<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.borderTopLeftRadius}</InputGroup.Text>
						<Button variant="outline-secondary" onClick={() => handleBorderRadius('ALL', 'PLUS')}>+</Button>
					</InputGroup>
				</Form.Group>
			) : (
				<>
					<div className='d-flex justify-content-start align-items-center gap-3 mb-2 mt-2'>
						<Form.Group>
							<Form.Label className='m-0'>Top Left</Form.Label>
							<InputGroup>
								<Button variant="outline-secondary" onClick={() => handleBorderRadius('TOP_LEFT', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.borderTopLeftRadius}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handleBorderRadius('TOP_LEFT', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
						<Form.Group>
							<Form.Label className='m-0'>Top Right</Form.Label>
							<InputGroup>
								<Button variant="outline-secondary" onClick={() => handleBorderRadius('TOP_RIGHT', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.borderTopRightRadius}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handleBorderRadius('TOP_RIGHT', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
					</div>
					<div className='d-flex justify-content-start align-items-center gap-3'>
						<Form.Group>
							<Form.Label className='m-0'>Bottom Left</Form.Label>
							<InputGroup>
								<Button variant="outline-secondary" onClick={() => handleBorderRadius('BOTTOM_LEFT', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.borderBottomLeftRadius}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handleBorderRadius('BOTTOM_LEFT', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
						<Form.Group>
							<Form.Label className='m-0'>Bottom Right</Form.Label>
							<InputGroup>
								<Button variant="outline-secondary" onClick={() => handleBorderRadius('BOTTOM_RIGHT', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.borderBottomRightRadius}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handleBorderRadius('BOTTOM_RIGHT', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
					</div>
				</>
			)}
		</>
	)
}

export default BorderRadius;