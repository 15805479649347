import React, { useEffect, useRef, useState } from 'react';
import {useDroppable} from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Event, RsvpFormComponent, RsvpFormPageStyles } from 'types';
import HeadingComponent from './components/heading';
import ParagraphComponent from './components/paragraph';
import ImageComponent from './components/image';
import ButtonComponent from './components/button';
import DataFieldComponent from './components/data-field';
import SpacerComponent from './components/spacer';
import DividerComponent from './components/divider';
import { Badge, Button } from 'react-bootstrap';
import { apiSaveRsvpFormPageComponents } from 'api/rsvpForms';

type PropsTypes = {
	activeEvent: Event
	formId: string | undefined
	activePage: string
	components: RsvpFormComponent[],
	pageStyles: RsvpFormPageStyles,
	updateComponent: (component: RsvpFormComponent) => void
	duplicateComponent: (component: RsvpFormComponent) => void
	deleteComponent: (component: RsvpFormComponent) => void
	setOpenThemeSidebar: React.Dispatch<React.SetStateAction<boolean>>
}

// Declare a timeout variable
let timeoutId: NodeJS.Timeout;

const FormArea = ({
	activeEvent,
	formId,
	activePage,
	components,
	pageStyles,
	updateComponent,
	duplicateComponent,
	deleteComponent,
	setOpenThemeSidebar,
}: PropsTypes) => {
	const {isOver, setNodeRef} = useDroppable({
		id: 'form-area',
	});
	const [isSaved, setIsSaved] = useState<string>('');
	

	useEffect(() => {
		(async () => {
			// load new page components then assign in setFormComponents
			if (activeEvent && formId && activePage) {
				// Set a timeout to run the API call after 5 seconds
				setIsSaved('Saving...');
				timeoutId = setTimeout(async () => {
					console.log('Saving components for page... ', activePage, components);
					const response = await apiSaveRsvpFormPageComponents(activeEvent.id, formId, activePage, components);
	
					if (response.success) {
						// Handle success
						setIsSaved('Changes saved');
						setTimeout(() => {
							setIsSaved('')
						}, 2000)
					}
				}, 5000);
			}
		})();
		
		// Cleanup function to clear the timeout if useEffect runs again
		return () => clearTimeout(timeoutId);
	}, [components, activePage])
	


	return (
		<div id='form-area' className='form-area p-3' ref={setNodeRef} style={pageStyles.body}>

			<Button variant='white' size='sm' 
				style={{
					borderRadius: 20,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: '#777',
					position: 'absolute',
					top: '20px',
					left: '20px',
				}}
				onClick={() => setOpenThemeSidebar(true)}
			>
				<svg fill="#777" width="14px" height="14px" className='me-1' viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
					<path d="M392.26 1042.5c137.747-57.67 292.85-15.269 425.873 116.217l4.394 4.833c116.656 146.425 149.5 279.119 97.873 394.237-128.85 287.138-740.692 328.77-810.005 332.504L0 1896.442l61.953-91.83c.989-1.539 105.013-158.728 105.013-427.192 0-141.811 92.6-279.558 225.294-334.92ZM1728.701 23.052c54.923-1.099 99.96 15.268 135.111 49.43 40.643 40.644 58.109 87.877 56.021 140.603C1908.85 474.52 1423.33 953.447 1053.15 1280.79c-24.276-64.81-63.711-136.21-125.335-213.102l-8.787-9.886c-80.078-80.187-169.163-135.11-262.423-161.473C955.276 558.002 1460.677 33.927 1728.701 23.052Z" fill-rule="evenodd"/>
				</svg>
				Theme
			</Button>

			{(isSaved != '') ? (
				<Badge
					bg='secondary'
					style={{
						position: 'fixed',
						bottom: '20px',
						right: '20px',
						zIndex: 9999,
					}}
				>{isSaved}</Badge>
			) : null}

			<div className="page-container"
				style={pageStyles.container}
			>
				{(components.length === 0) ? (
					<div className="drop-placeholder">drop here</div>
				) : null}

				<SortableContext 
					items={components}
					strategy={verticalListSortingStrategy}
				>
				{components.map((component: RsvpFormComponent, i: number) => {
					if (component.type === 'HEADING') {
						return <HeadingComponent 
							key={component.id} 
							component={component} 
							updateComponent={updateComponent} 
							duplicateComponent={duplicateComponent} 
							deleteComponent={deleteComponent} 
						/>
					}
					else if (component.type === 'PARAGRAPH') {
						return <ParagraphComponent 
							key={component.id} 
							component={component} 
							updateComponent={updateComponent} 
							duplicateComponent={duplicateComponent} 
							deleteComponent={deleteComponent} 
						/>
					}
					else if (component.type === 'IMAGE') {
						return <ImageComponent 
							key={component.id} 
							component={component} 
							updateComponent={updateComponent} 
							duplicateComponent={duplicateComponent} 
							deleteComponent={deleteComponent} 
						/>
					}
					else if (component.type === 'BUTTON') {
						return <ButtonComponent 
							key={component.id} 
							component={component} 
							updateComponent={updateComponent} 
							duplicateComponent={duplicateComponent} 
							deleteComponent={deleteComponent} 
						/>
					}
					else if (component.type === 'SPACER') {
						return <SpacerComponent 
							key={component.id} 
							component={component} 
							updateComponent={updateComponent} 
							duplicateComponent={duplicateComponent} 
							deleteComponent={deleteComponent} 
						/>
					}
					else if (component.type === 'DIVIDER') {
						return <DividerComponent 
							key={component.id} 
							component={component} 
							updateComponent={updateComponent} 
							duplicateComponent={duplicateComponent} 
							deleteComponent={deleteComponent} 
						/>
					}
					else if (component.type === 'DATA_FIELD') {
						return <DataFieldComponent 
							key={component.id} 
							component={component} 
							updateComponent={updateComponent} 
							duplicateComponent={duplicateComponent} 
							deleteComponent={deleteComponent} 
						/>
					}
					return null;
					// return (
					// 	<div key={component.id}>{component.type}</div>
					// )
				})}
				</SortableContext>
			</div>
		</div>
	)
}

export default React.memo(FormArea);