import React, { useEffect, useRef, useState } from 'react';
import { RsvpFormComponent } from 'types';
import { Button, ButtonGroup, Form, Image, InputGroup, Modal, Overlay, Tooltip } from 'react-bootstrap';
import { apiUploadRsvpFormImage } from 'api/rsvpForms';

type PropsTypes = {
	styles: React.CSSProperties
	options: any
	onUpdate: (styles: React.CSSProperties, options: any) => void
}

const MarginOptions = ({
	styles,
	options,
	onUpdate,
}: PropsTypes) => {

	const handle = (opt = 'ALL', type = 'PLUS') => {
		let s = {
			marginTop: styles.marginTop || 0,
			marginBottom: styles.marginBottom || 0,
			marginLeft: styles.marginLeft || 0,
			marginRight: styles.marginRight || 0,
		}
		if (opt === 'ALL') {
			let v = styles.marginTop as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.marginTop = v;
			s.marginBottom = v;
			s.marginLeft = v;
			s.marginRight = v;
		}
		else if (opt === 'TOP') {
			let v = styles.marginTop as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.marginTop = v;
		}
		else if (opt === 'BOTTOM') {
			let v = styles.marginBottom as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.marginBottom = v;
		}
		else if (opt === 'LEFT') {
			let v = styles.marginLeft as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.marginLeft = v;
		}
		else if (opt === 'RIGHT') {
			let v = styles.marginRight as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.marginRight = v;
		}

		onUpdate(s, options)
	}
	
	return (
		<>
			<Form.Group className='d-flex justify-content-between align-items-center'>
				<Form.Label className='m-0'>Margin</Form.Label>
				<Form.Check
					type="switch"
					id="margin-switch"
					label="More Options"
					checked={options.allSideMargin === false}
					onChange={e => onUpdate(styles, {allSideMargin: !options.allSideMargin})}
				/>
			</Form.Group>
			{(options.allSideMargin === true) ? (
				<Form.Group>
					<Form.Label className='m-0'>All Sides</Form.Label>
					<InputGroup size='sm'>
						<Button variant="outline-secondary" onClick={() => handle('ALL', 'MINUS')}>-</Button>
						<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.marginTop}</InputGroup.Text>
						<Button variant="outline-secondary" onClick={() => handle('ALL', 'PLUS')}>+</Button>
					</InputGroup>
				</Form.Group>
			) : (
				<>
					<div className='d-flex justify-content-start align-items-center gap-3 mb-2 mt-2'>
						<Form.Group>
							<Form.Label className='m-0'>Top</Form.Label>
							<InputGroup size='sm'>
								<Button variant="outline-secondary" onClick={() => handle('TOP', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.marginTop}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handle('TOP', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
						<Form.Group>
							<Form.Label className='m-0'>Bottom</Form.Label>
							<InputGroup size='sm'>
								<Button variant="outline-secondary" onClick={() => handle('BOTTOM', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.marginBottom}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handle('BOTTOM', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
					</div>
					<div className='d-flex justify-content-start align-items-center gap-3'>
						<Form.Group>
							<Form.Label className='m-0'>Left</Form.Label>
							<InputGroup size='sm'>
								<Button variant="outline-secondary" onClick={() => handle('LEFT', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.marginLeft}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handle('LEFT', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
						<Form.Group>
							<Form.Label className='m-0'>Right</Form.Label>
							<InputGroup size='sm'>
								<Button variant="outline-secondary" onClick={() => handle('RIGHT', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.marginRight}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handle('RIGHT', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
					</div>
				</>
			)}
		</>
	)
}

export default MarginOptions;