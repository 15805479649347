import React, { useEffect, useRef, useState } from 'react';
import { RsvpFormComponent } from 'types';
import { Button, ButtonGroup, Form, Image, InputGroup, Modal, Overlay, Tooltip } from 'react-bootstrap';
import { apiUploadRsvpFormImage } from 'api/rsvpForms';

type PropsTypes = {
	styles: React.CSSProperties
	options: any
	onUpdate: (styles: React.CSSProperties, options: any) => void
}

const PaddingOptions = ({
	styles,
	options,
	onUpdate,
}: PropsTypes) => {

	const handle = (opt = 'ALL', type = 'PLUS') => {
		let s = {
			paddingTop: styles.paddingTop || 0,
			paddingBottom: styles.paddingBottom || 0,
			paddingLeft: styles.paddingLeft || 0,
			paddingRight: styles.paddingRight || 0,
		}
		if (opt === 'ALL') {
			let v = styles.paddingTop as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.paddingTop = v;
			s.paddingBottom = v;
			s.paddingLeft = v;
			s.paddingRight = v;
		}
		else if (opt === 'TOP') {
			let v = styles.paddingTop as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.paddingTop = v;
		}
		else if (opt === 'BOTTOM') {
			let v = styles.paddingBottom as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.paddingBottom = v;
		}
		else if (opt === 'LEFT') {
			let v = styles.paddingLeft as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.paddingLeft = v;
		}
		else if (opt === 'RIGHT') {
			let v = styles.paddingRight as number;
			if (type === 'MINUS') {
				v = (v > 0) ? (v - 1) : v;
			} else {
				v += 1;
			}
			s.paddingRight = v;
		}

		onUpdate(s, options)
	}
	
	return (
		<>
			<Form.Group className='d-flex justify-content-between align-items-center'>
				<Form.Label className='m-0'>Padding</Form.Label>
				<Form.Check
					type="switch"
					id="padding-switch"
					label="More Options"
					checked={options.allSidePadding === false}
					onChange={e => onUpdate(styles, {allSidePadding: !options.allSidePadding})}
				/>
			</Form.Group>
			{(options.allSidePadding === true) ? (
				<Form.Group>
					<Form.Label className='m-0'>All Sides</Form.Label>
					<InputGroup size='sm'>
						<Button variant="outline-secondary" onClick={() => handle('ALL', 'MINUS')}>-</Button>
						<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.paddingTop}</InputGroup.Text>
						<Button variant="outline-secondary" onClick={() => handle('ALL', 'PLUS')}>+</Button>
					</InputGroup>
				</Form.Group>
			) : (
				<>
					<div className='d-flex justify-content-start align-items-center gap-3 mb-2 mt-2'>
						<Form.Group>
							<Form.Label className='m-0'>Top</Form.Label>
							<InputGroup size='sm'>
								<Button variant="outline-secondary" onClick={() => handle('TOP', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.paddingTop}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handle('TOP', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
						<Form.Group>
							<Form.Label className='m-0'>Bottom</Form.Label>
							<InputGroup size='sm'>
								<Button variant="outline-secondary" onClick={() => handle('BOTTOM', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.paddingBottom}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handle('BOTTOM', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
					</div>
					<div className='d-flex justify-content-start align-items-center gap-3'>
						<Form.Group>
							<Form.Label className='m-0'>Left</Form.Label>
							<InputGroup size='sm'>
								<Button variant="outline-secondary" onClick={() => handle('LEFT', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.paddingLeft}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handle('LEFT', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
						<Form.Group>
							<Form.Label className='m-0'>Right</Form.Label>
							<InputGroup size='sm'>
								<Button variant="outline-secondary" onClick={() => handle('RIGHT', 'MINUS')}>-</Button>
								<InputGroup.Text style={{ borderColor: '#6c757d' }}>{styles.paddingRight}</InputGroup.Text>
								<Button variant="outline-secondary" onClick={() => handle('RIGHT', 'PLUS')}>+</Button>
							</InputGroup>
						</Form.Group>
					</div>
				</>
			)}
		</>
	)
}

export default PaddingOptions;