import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { DomainEmails, EmailsSchedule, EmailTemplate, EmailTemplateType, GuestList } from 'types';
import { ButtonGroup, Form, InputGroup, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { EmailTemplateStatuses, EmailTemplateTypes, GuestStatus, TimeZones } from 'constant';
import { batch } from 'react-redux';
import { apiCancelScheduleEmails, apiCreateTemplate, apiGetEmailSenders, apiGetScheduleEmails, apiScheduleEmails } from 'api/emailTemplate';
import { useNavigate } from 'react-router-dom';
import { apiGetGuestLists } from 'api/guestLists';
import dayjs from 'dayjs';
import { useAppSelector } from 'redux/hooks';
import { toast } from 'react-toastify';


type ScheduleEmailProps = {
	template: EmailTemplate;
}

const ScheduleEmail = ({ template }: ScheduleEmailProps) => {
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const [showSchedulePopup, setShowSchedulePopup] = useState(false);
	const [showCancelPopup, setShowCancelPopup] = useState(false);
	const [lists, setLists] = useState<GuestList[]>([]);
	const [selectedId, setSelectedId] = useState<string | number>('');
	const [filterStatus, setFilterStatus] = useState<string>('all');
	const [scheduleDate, setScheduleDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
	const [scheduleTime, setScheduleTime] = useState<string>(dayjs().add(1, 'hour').format('HH:00'));
	const [scheduleTimezone, setScheduleTimezone] = useState<string>((activeEvent) ? activeEvent.timezone : '');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isScheduled, setIsScheduled] = useState<EmailsSchedule | null>(null);

	useEffect(() => {
		(async () => {
			if (template && activeEvent) {
				setIsLoading(true);
				const responseSchedule = await apiGetScheduleEmails(activeEvent.id, template.id as number)

				if (responseSchedule.success) {
					setIsScheduled(responseSchedule.data)
				}

				
				const response = await apiGetGuestLists(activeEvent.id)

				if (response.success) {
					setLists(response.data.lists)
					
					if (response.data.lists.length && response.data.lists[0].id) {
						setSelectedId(response.data.lists[0].id)
					}
				}
				setIsLoading(false);
			}
		})()
	}, [template, activeEvent])


	const handleSchedule = async () => {
		if (!activeEvent) return;

		const data: {
			list_id: string | number,
			filter_status: string,
			schedule_date: string,
			schedule_time: string,
			schedule_timezone: string,
		} = {
			list_id: selectedId,
			filter_status: filterStatus,
			schedule_date: scheduleDate,
			schedule_time: scheduleTime,
			schedule_timezone: scheduleTimezone
		}
		console.log('handleSchedule', data)

		setIsLoading(true);
		const response = await apiScheduleEmails(activeEvent.id, template.id as number, data);
		if (response.success) {
			batch(() => {
				setIsLoading(false);
				setShowSchedulePopup(false)
				setIsScheduled(response.data)
			})
			toast.success(response.message)
		} else {
			setIsLoading(false);
			toast.error(response.message)
		}
	}

	const cancelSchedule = async () => {
		if (activeEvent && isScheduled) {
			const response = await apiCancelScheduleEmails(activeEvent.id, isScheduled.id);

			if (response.success) {
				batch(() => {
					setShowCancelPopup(false)
					setIsScheduled(null)
				})
				toast.success(response.message)
			} else {
				toast.error(response.message)
			}
		}
	}

	if (!template || template.status !== EmailTemplateStatuses.ACTIVE) {
		return null;
	}

	return (
		<>
		{(isLoading) ? (
			<div className='text-end'>
				<Spinner animation="border" role="status" size='sm'>
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
		) : (
			<>
				{(isScheduled) ? (
					<div className='text-end'>
						{(isScheduled.is_completed == 1) ? (
							<div className='d-flex align-items-center justify-content-end'>
								<span className='text-success'>Sent</span> 
								<Button type='button' variant='link' size='sm' disabled>
									<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path 
											fill-rule="evenodd" 
											clip-rule="evenodd" 
											d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" 
											fill="#198754"
										/>
									</svg>
								</Button>
							</div>
						) : (
						<div className='d-flex align-items-center justify-content-end'>
							<span className='text-success'>Scheduled</span> 
							<Button type='button' variant='link' size='sm' onClick={() => setShowCancelPopup(true)}>
								<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path 
										fill-rule="evenodd" 
										clip-rule="evenodd" 
										d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z" 
										fill="#dc3545"
									/>
								</svg>
							</Button>
						</div>
						)}
						<div className='small text-muted'>{dayjs(`${isScheduled.date} ${isScheduled.time}`).format('DD/MM/YYYY h:mma')}</div>
					</div>
				) : (
					<Button type='button' variant='dark' onClick={() => setShowSchedulePopup(true)}>Send</Button>
				)}
			</>
		)}


		{showSchedulePopup && (
		<Modal show={showSchedulePopup} className={`scheduleEmailModal_${template.id}`} size='lg' onHide={() => setShowSchedulePopup(false)} aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='m-0'>Schedule Email - {template.name}</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>

				<Row>
					<Col>
						<Form.Group className="mb-3">
							<Form.Label className='m-0 small text-muted'>Sender name</Form.Label>
							<Form.Control type="text" plaintext readOnly value={template.sender_name} />
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-3">
							<Form.Label className='m-0 small text-muted'>Sender email address</Form.Label>
							<Form.Control type="text" plaintext readOnly value={template.sender_name} />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col></Col>
					<Col>
						<Form.Group className="mb-3">
							<Form.Label className='m-0 small text-muted'>Reply-to email address</Form.Label>
							<Form.Control type="text" plaintext readOnly value={template.sender_reply_emails} />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group>
							<Form.Label className='m-0 small text-muted'>Email subject line</Form.Label>
							<Form.Control type="text" plaintext readOnly value={template.subject} />
						</Form.Group>
					</Col>
				</Row>

				<hr />

				<Row>
					<Col>
						<Form.Group className="mb-4">
							<Form.Label className=''>Send to Guest list</Form.Label>
							<Form.Select value={selectedId || ''} onChange={e => setSelectedId(e.target.value)}>
								{lists.map((list, index) => {
									return <option key={index} value={(list.id) ? list.id : ''}>{list.list_name}</option>
								})}
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-4">
							<Form.Label className=''>Filter by Guest Status (Optional)</Form.Label>
							<Form.Select value={filterStatus} onChange={e => setFilterStatus(e.target.value)}>
								<option value='all'>All Guest in Guest List</option>
								<option value={GuestStatus.CONFIRMED}>Confirmed</option>
								<option value={GuestStatus.CHECKED_IN}>Checked in</option>
								<option value={GuestStatus.CANCELLED}>Cancelled</option>
								<option value={GuestStatus.UN_CONFIRMED}>Unconfirmed</option>
							</Form.Select>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col md={2}></Col>
					<Col>	
						<Form.Group className="mb-3">
							<Form.Label>Schedule Email Sending (Date, Time & Timezone)</Form.Label>
							<InputGroup>
								<Form.Control type="date" min={(dayjs().format('YYYY-MM-DD'))} value={scheduleDate} onChange={e => setScheduleDate(e.target.value)}  />
								
								<Form.Control type="time" value={scheduleTime} onChange={e => setScheduleTime(e.target.value)} />
								
								<Form.Select aria-label="Default select example" value={scheduleTimezone} onChange={e => setScheduleTimezone(e.target.value)} >
									{TimeZones.map(timezone => {
										return (
											<option key={timezone} value={timezone}>{timezone}</option>
										)
									})}
								</Form.Select>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col md={2}></Col>
				</Row>

			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-dark" onClick={() => setShowSchedulePopup(false)} style={{ width: 'auto', flexGrow: 1, padding: '1rem' }}>
					Cancel
				</Button>

				<Button variant="dark" onClick={handleSchedule} style={{ width: 'auto', flexGrow: 1.8, padding: '1rem' }} disabled={isLoading}>
					<div className='d-flex align-items-center justify-content-between'>
						<span>Schedule Email</span>
						<span className="arrow">
							<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
							</svg>
						</span>
					</div>
				</Button>
			</Modal.Footer>
		</Modal>
		)}


		{showCancelPopup && (
		<Modal aria-labelledby="contained-modal-title-vcenter" centered show={showCancelPopup} onHide={() => setShowCancelPopup(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Delete Scheduled Date</Modal.Title>
			</Modal.Header>

			<Modal.Body className='text-center'>
				<p>Are you sure you want to delete this scheduled date?</p>

				<p>No emails will be sent.</p>

				<div className='mt-4 mb-3 d-grid'>
					<Button variant="danger" onClick={cancelSchedule}>
					DELETE SCHEDULED DATE
					</Button>
				</div>
			</Modal.Body>
		</Modal>
		)}


		</>
	)
}

export default ScheduleEmail;