import apiService from "services/ApiService";
import cacheService from "services/CacheService";
import { ResponseObject, RsvpForm, RsvpFormComponent, RsvpFormPageSettings, RsvpFormType } from "types"

export const apiGetRsvpForms = async (
	eventId: string | number,
): Promise<ResponseObject<RsvpForm[]>> => {

	return await apiService.get(`/rsvp/${eventId}`);
}

export const apiCheckRsvpFormsSlug = async (
	eventId: string | number,
	slug: string,
	formId: string | number | null = null
): Promise<ResponseObject<null>> => {
	return await apiService.post(`/rsvp/check-slug/${eventId}`, {
		slug,
		formId
	});
}

export const apiCreateRsvpForm = async (
	eventId: string | number,
	// data: Omit<RsvpForm, 'id' | 'event_id'>
	data: {
		guest_list_id: string | number
		type: RsvpFormType
		name: string
		slug: string
	}
): Promise<ResponseObject<RsvpForm>> => {
	return await apiService.post(`/rsvp/create/${eventId}`, {
		...data
	});
}

export const apiGetRsvpFormDetails = async (
	eventId: string | number,
	formId: string | number,
): Promise<ResponseObject<RsvpForm>> => {

	return await apiService.get(`/rsvp/form/${eventId}/${formId}`);
}


export const apiUpdateRsvpForm = async (
	eventId: string | number,
	formId: string | number,
	data: RsvpForm,
): Promise<ResponseObject<RsvpForm>> => {
	return await apiService.post(`/rsvp/update/${eventId}/${formId}`, {
		...data
	});
}

export const apiCopyRsvpForm = async (
	eventId: string | number,
	formId: string | number,
): Promise<ResponseObject<RsvpForm>> => {

	return await apiService.post(`/rsvp/form-copy/${eventId}/${formId}`, {});
}

export const apiDeleteRsvpForm = async (
	eventId: string | number,
	formId: string | number,
): Promise<ResponseObject<null>> => {

	return await apiService.delete(`/rsvp/form/${eventId}/${formId}`);
}

export const apiDownloadGuestFormLinks = async (
	eventId: number | string, 
	formId: string | number,
): Promise<ResponseObject<string>> => {
	return await apiService.get(`/rsvp/download-guest-links/${eventId}/${formId}`);
}

export const apiUploadRsvpFormImage = async (
	data: any
): Promise<ResponseObject<string>> => {
	return await apiService.postMultipart(`/rsvp/upload-image`, data);
}

export const apiGetRsvpFormPageComponents = async (
	eventId: string | number,
	formId: string | number,
	pageType: string,
	abortController: AbortController,
): Promise<ResponseObject<RsvpFormComponent[]>> => {

	return await apiService.get(`/rsvp/form/${eventId}/${formId}/page/components`, {
		page_type: pageType
	}, abortController);
}

export const apiSaveRsvpFormPageComponents = async (
	eventId: string | number,
	formId: string | number,
	pageType: string,
	components: RsvpFormComponent[]
): Promise<ResponseObject<RsvpFormComponent[]>> => {

	return await apiService.post(`/rsvp/form/${eventId}/${formId}/page/components`, {
		page_type: pageType,
		components
	});
}

export const apiGetRsvpFormPageSettings = async (
	eventId: string | number,
	formId: string | number,
	pageType: string,
	abortController: AbortController,
): Promise<ResponseObject<RsvpFormPageSettings>> => {

	return await apiService.get(`/rsvp/form/${eventId}/${formId}/page/settings`, {
		page_type: pageType
	}, abortController);
}

export const apiSaveRsvpFormPageSettings = async (
	eventId: string | number,
	formId: string | number,
	pageType: string,
	settings: any,
): Promise<ResponseObject<RsvpFormPageSettings>> => {

	return await apiService.post(`/rsvp/form/${eventId}/${formId}/page/settings`, {
		page_type: pageType,
		settings,
	});
}