import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { EventDataField, Guest, NumericFlag, PrinterLabel, TextAlign } from 'types';
import { apiGetEventPrinterLabels, apiUpdateEventPrinterLabels } from 'api/printers';
import { apiGetEventDataFields } from 'api/events';
import { Link, Navigate } from 'react-router-dom';
import { PrintFontSizes, PrintFonts } from 'constant';
import { batch } from 'react-redux';
import { setHeaderTitle } from 'redux/interfaceManger';
import { Card, Col, InputGroup, Row } from 'react-bootstrap';
import { setActiveEvent } from 'redux/eventSlice';
import { toast } from 'react-toastify';
import BadgePreviewPopup from './components/BadgePreviewPopup';


type BadgeTemplateType = {
	id: number
	title: string
	width: number | null
	height: number | null
	preview: {
		width: number
		height: number
	},
	defaultOrientation: string
	sizes: any
	// sizes: null | {
	// 	'L': {
	// 		widths: number[]
	// 		heights: number[]
	// 	}
	// 	'P': {
	// 		widths: number[]
	// 		heights: number[]
	// 	}
	// }
}

const BadgeTemplates: BadgeTemplateType[] = [
	{
		id: 1,
		title: '62mm Continuous Length DK22205',
		width: 62,
		height: null,
		preview: {
			width: 350,
			height: 250,
		},
		defaultOrientation: 'P',
		sizes: {
			'L': {
				widths: [70, 80, 85, 90, 100],
				heights: [62],
			},
			'P': {
				widths: [62],
				heights: [30, 40, 50, 60],
			}
		}
	},
	{
		id: 2,
		title: '90mm x 38mm Large Address DK11208',
		width: 90,
		height: 38,
		preview: {
			width: 400,
			height: 150,
		},
		defaultOrientation: 'L',
		sizes: {
			'L': {
				widths: [90],
				heights: [38],
			},
			'P': {
				widths: [38],
				heights: [90],
			}
		}
	},
	{
		id: 3,
		title: 'Custom',
		width: null,
		height: null,
		preview: {
			width: 150,
			height: 250,
		},
		defaultOrientation: 'L',
		sizes: null
	}
]

const BadgeCreator = () => {
	const dispatch = useAppDispatch();
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const [eventDataFields, setEventDataFields] = useState<EventDataField[]>([]);
	// const [labels, setLabels] = useState<PrinterLabel[]>([]);
	const [allChangesSaved, setAllChangesSaved] = useState<boolean>(true);
	const [selectedTemplate, setSelectedTemplate] = useState<BadgeTemplateType | null>(null);
	const [canvasWidth, setCanvasWidth] = useState<number>(0);
	const [canvasHeight, setCanvasHeight] = useState<number>(0);
	const [canvasWidths, setCanvasWidths] = useState<number[]>([]);
	const [canvasHeights, setCanvasHeights] = useState<number[]>([]);
	const [badgeLines, setBadgeLines] = useState<PrinterLabel[]>([]);
	const [selectedLine, setSelectedLine] = useState<number | null>(null);
	const [orientation, setOrientation] = useState<string>('P');
	const [showPreviewPopup, setShowPreviewPopup] = useState<boolean>(false);

	const selectedBadgeLine = badgeLines.find(l => l.line_number === selectedLine);

	const isLandscape = (orientation === 'L');

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Name Badge Printing'))
		})
	}, [])

	useEffect(() => {
		(async () => {
			if (activeEvent) {
				const response = await apiGetEventPrinterLabels(activeEvent.id)

				if (response.success) {
					setTimeout(() => {
						console.log('4')
						setBadgeLines(response.data.labels)
					}, 1200)
					if (response.data.eventPrinter) {
						console.log('1')
						setSelectedTemplate(BadgeTemplates.find(t => t.id === response.data.eventPrinter.template_id) || null)
						setOrientation(response.data.eventPrinter.orientation)
						setTimeout(() => {
							batch(() => {
								console.log('3')
								setCanvasWidth(response.data.eventPrinter.page_width)
								setCanvasHeight(response.data.eventPrinter.page_height)
							})
						}, 600)
					}
				}


				const fieldResponse = await apiGetEventDataFields(activeEvent.id);
				if (fieldResponse.success) {
					setEventDataFields([
						...fieldResponse.data,
						{
							...fieldResponse.data[0],
							field: "unique_guest_id",
							display_name: "Unique ID",
							type: "text",
						},
						{
							...fieldResponse.data[0],
							field: 'first_name|last_name',
							display_name: 'First & Last Name'
						}, 
						{
							...fieldResponse.data[0],
							field: 'last_name|first_name',
							display_name: 'Last & First Name'
						}, 
					]);
				}
			}
		})()
	}, [activeEvent])


	useEffect(() => {
		if (canvasHeight > 10) {
			let numbLines = scaleCanvas(canvasHeight) - scaleCanvas(10); // remove top and bottom 5mm card padding
			numbLines = numbLines / scaleCanvas(10); // 10mm height of a line
			setBadgeLines([...new Array(Math.floor(numbLines)).fill(1).map((_l, i) => {
				return {
					data_type: 'TEXT',
					line_number: (i + 1),
					guest_field: null,
					font: 'Helvetica',
					font_size: 18,
					is_bold: 0 as NumericFlag,
					is_italic: 0 as NumericFlag,
					is_underline: 0 as NumericFlag,
					text_align: 'left' as TextAlign,
				}
			})])
		}
	}, [canvasHeight])

	useEffect(() => {
		if (selectedTemplate) {
			if (selectedTemplate.id === 3) {
				if (orientation === 'L') {
					setCanvasWidth(62);
					setCanvasHeight(50);
				} else {
					setCanvasWidth(80);
					setCanvasHeight(62);
				}
			} else {
				console.log('2')
				setCanvasWidth(selectedTemplate.sizes[orientation].widths[0]) // get first width
				setCanvasHeight(selectedTemplate.sizes[orientation].heights[selectedTemplate.sizes[orientation].heights.length - 1]) // get last height
				setCanvasWidths(selectedTemplate.sizes[orientation].widths)
				setCanvasHeights(selectedTemplate.sizes[orientation].heights)
			}
		}
	}, [selectedTemplate, orientation])

	// scale original mm size, for screens
	const scaleCanvas = (size: number) => {
		return size * 1.5;
	}

	const selectTemplate = (template: BadgeTemplateType) => {
		batch(() => {
			setSelectedTemplate(template)
			setOrientation(template.defaultOrientation)
		})
	}

	const selectLine = (line_number: number) => {
		if (selectedLine && selectedLine === line_number) {
			setSelectedLine(null)
		} else {
			setSelectedLine(line_number)
		}
	}

	const addElement = (type: string) => {
		if (!selectedLine) {
			alert('Please select line first!');
			return
		}

		setAllChangesSaved(false)
		setBadgeLines([...badgeLines.map(line => {
			if (line.line_number === selectedLine) {
				return { 
					...line, 
					data_type: type,
					guest_field: eventDataFields[0].field as keyof Guest,
				}
			} else {
				return line
			}
		})])
	}

	const removeElement = () => {
		if (!selectedLine) {
			return
		}

		setAllChangesSaved(false)
		setBadgeLines([...badgeLines.map(line => {
			if (line.line_number === selectedLine) {
				return { 
					...line, 
					data_type: 'TEXT',
					guest_field: null,
					font: 'Helvetica',
					font_size: 18,
					is_bold: 0 as NumericFlag,
					is_italic: 0 as NumericFlag,
					is_underline: 0 as NumericFlag,
					text_align: 'left' as TextAlign,
				}
			} else {
				return line
			}
		})])
	}

	const handleLabelsChange = (key: string, value: string | number | boolean): void => {
		batch(() => {
			setAllChangesSaved(false)
			setBadgeLines(badgeLines.map((data, i) => {
				if (data.line_number === selectedLine) {
					return {
						...data,
						[key]: (value && value !== '') ? value : null,
					};
				}
	
				return data;
			}))
		})
	}

	const updateLabels = async () => {
		if (activeEvent && selectedTemplate) {
			console.log({canvasWidth, canvasHeight, badgeLines})
			const response = await apiUpdateEventPrinterLabels(activeEvent.id, badgeLines, canvasWidth, canvasHeight, orientation, selectedTemplate.id)

			if (response.success) {
				toast.success(response.message)
				batch(() => {
					setBadgeLines(response.data)
					setAllChangesSaved(true)
				})

				dispatch(setActiveEvent({...activeEvent, has_badge_printing: 1}))
			} else {
				toast.error(response.message)
			}
		}
	}

	if (!activeEvent) {
		return <Navigate to="/" />
	}


	if (!selectedTemplate) {
		return (
			<>
				<div className="cp_badge_print_table cp_table">
					<div className="p-4">
						<h4>Badge Templates</h4>

						<div className='mt-4 mb-5 d-flex justify-content-start align-items-end'>
						{BadgeTemplates.map(template => (
							<div key={template.id} className="mb-3 me-3">
								<Card onClick={() => selectTemplate(template)}>
									<Card.Header>
										<div className="template-preview" style={{
											background: '#fff',
											width: template.preview.width / 1.2,
											height: template.preview.height / 1.2,
											boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
											borderRadius: '6px'
										}}></div>
									</Card.Header>
									<Card.Body>
										<Card.Text className='text-center text-muted small'>{template.title}</Card.Text>
									</Card.Body>
								</Card>
							</div>
						))}
						</div>
					</div>
				</div>
			</>
		)
	}


	return (
		<>
			<div className="cp_badge_print_table cp_table">
				<div className="p-4">
					<h4>Badge Creator</h4>

					<Row className="badge-creator-app">
						<Col md={2} className="elements-area">
							<h5>Elements</h5>

							<div className="elements-container">
								<button type='button' className="elements-item me-4"
									onClick={() => addElement('TEXT')}
								>
									<span className='icon'>
										<img src={require('assets/images/font-size.png')} alt="text icon" style={{
											width: 40,
										}} />
									</span>
									<span className='text'>Text</span>
								</button>
								<button type='button' className="elements-item"
									onClick={() => addElement('BREAK')}
								>
									<span className='icon'>
										<img src={require('assets/images/icon-line-break.png')} alt="text icon" style={{
											width: 40,
										}} />
									</span>
									<span className='text'>Break</span>
								</button>
								<button type='button' className="elements-item"
									onClick={() => addElement('QR')}
								>
									<span className='icon'>
										<img src={require('assets/images/qr-code.png')} alt="qr icon" style={{
											width: 40,
										}} />
									</span>
									<span className='text'>QR</span>
								</button>
							</div>
						</Col>
						<Col md={7} className="canvas-area">
							<div className="size-selector">
								<InputGroup className="" style={{ maxWidth: 350 }}>
										{(selectedTemplate.id === 3) ? (
											<Form.Control 
												type='number'
												min={10}
												value={canvasWidth} 
												onChange={e => setCanvasWidth(parseInt(e.target.value))}
											/>
										) : (
											<Form.Select value={canvasWidth} 
												disabled={(canvasWidths.length <= 1)}
												onChange={e => setCanvasWidth(parseInt(e.target.value))}
											>
												{canvasWidths.map((w, i) => <option key={i} value={w}>{w}mm</option>)}
											</Form.Select>
										)}
									<InputGroup.Text id="size-selector-by">X</InputGroup.Text>
									{(selectedTemplate.id === 3) ? (
											<Form.Control 
												type='number'
												min={10}
												value={canvasHeight} 
												onChange={e => setCanvasHeight(parseInt(e.target.value))}
											/>
										) : (
											<Form.Select value={canvasHeight} 
												disabled={(canvasHeights.length <= 1)}
												onChange={e => setCanvasHeight(parseInt(e.target.value))}
											>
												{canvasHeights.map((h, i) => <option key={i} value={h}>{h}mm</option>)}
											</Form.Select>
										)}
									
									
									<Button variant="" onClick={() => setSelectedTemplate(null)}>
										<i>
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M13.6 4L16 6.4M4 16L4.76584 13.1919C4.81581 13.0087 4.84079 12.9171 4.87914 12.8317C4.91319 12.7558 4.95503 12.6837 5.00399 12.6166C5.05913 12.5409 5.12627 12.4737 5.26054 12.3395L11.4606 6.13941C11.5794 6.02061 11.6388 5.9612 11.7073 5.93895C11.7675 5.91937 11.8325 5.91937 11.8927 5.93895C11.9612 5.9612 12.0206 6.02061 12.1394 6.13941L13.8606 7.86059C13.9794 7.97939 14.0388 8.0388 14.0611 8.1073C14.0806 8.16755 14.0806 8.23245 14.0611 8.29271C14.0388 8.3612 13.9794 8.42061 13.8606 8.53941L7.66054 14.7395C7.52627 14.8737 7.45913 14.9409 7.38345 14.996C7.31625 15.045 7.24415 15.0868 7.16831 15.1209C7.08289 15.1592 6.99129 15.1842 6.80808 15.2342L4 16Z" 
													stroke="#1c1a1c" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
											</svg>
										</i>
									</Button>
								</InputGroup>

								<Button variant="" onClick={() => setOrientation(isLandscape ? 'P' : 'L')} title={isLandscape ? 'Landscape' : 'Portrait'}>
									<img src={require('assets/images/icons-rotate-page.png')} alt="text icon" style={{
										width: 30,
										transform: isLandscape ? 'rotate(-90deg)' : 'rotate(0deg)',
									}} />
								</Button>
							</div>
							<div className="canvas-container">
								<div className="canvas" style={{
									width: `${scaleCanvas(canvasWidth)}mm`,
									height: `${scaleCanvas(canvasHeight)}mm`,
								}}>

									{/* {new Array(Math.floor((canvasHeight - 10) / 10)).fill(1).map((l, i) => {
										return (
											<div key={i} className="line"></div>
										)
									})} */}
									
									{badgeLines.map((line) => {
										return (
											<div key={line.line_number} 
												className={`line ${line.line_number === selectedLine ? 'selected' : ''}`}
												style={{
													height: `${scaleCanvas(10)}mm`,
													fontFamily: (line.font) ? PrintFonts.find(f => f.value === line.font)?.uiFont : 'inherit',
													// fontSize: (line.font_size) ? `${line.font_size}px` : 'initial',
													fontSize: (line.font_size) ? `${scaleCanvas((line.font_size) * 0.26458333333)}mm` : 'initial',
													fontWeight: (line.is_bold === 1) ? 'bold' : 'normal',
													fontStyle: (line.is_italic === 1) ? 'italic' : 'normal',
													textDecoration: (line.is_underline === 1) ? 'underline' : 'normal',
													textAlign: (line.text_align) ? line.text_align : 'left',
												}}
												onClick={() => selectLine(line.line_number)}
											>
												{line.guest_field ? 
													(line.data_type === 'TEXT' ? 
														`(${(eventDataFields.find(f => f.field === line.guest_field)) ? eventDataFields.find(f => f.field === line.guest_field)?.display_name : line.guest_field})` : 
													(line.data_type === 'QR') ? (
														<img src={require('assets/images/qr-code.png')} alt="qr icon" style={{
															width: `${scaleCanvas(20)}mm`,
														}} />
													) : (
													<img src={require('assets/images/icon-line-break.png')} alt="qr icon" style={{
														width: `${scaleCanvas(5)}mm`,
														opacity: 0.3
													}} />
													)
													) : null}</div>
										)
									})}
									{/* <div className="line">(first name) (last name)</div> */}
									{/* <div className="line">(company) </div> */}

								</div>
							</div>
						</Col>
						<Col md={3} className="toolbox-area">
							
							{(selectedBadgeLine && selectedBadgeLine.guest_field !== null) ? (
								<>
									{(selectedBadgeLine.data_type === 'TEXT') ? (
										<>
										<div className="toolbox-container">
											<h5>Text Content</h5>

											<Row className='mb-3'>
												<Col>
													<Form.Select aria-label="Field" value={selectedBadgeLine?.guest_field || ''} onChange={e => handleLabelsChange('guest_field', e.target.value)}>
														<option value="">None</option>
														<optgroup label='Fields'>
														{eventDataFields.filter(field => field.field.indexOf('|') === -1).map(field => {
															return (
																<option value={field.field}>{field.display_name}</option>
															)
														})}
														</optgroup>
														<optgroup label='Combined Fields'>
														{eventDataFields.filter(field => field.field.indexOf('|') >= 0).map(field => {
															return (
																<option value={field.field}>{field.display_name}</option>
															)
														})}
														</optgroup>
													</Form.Select>
												</Col>
											</Row>
											<Row className='mb-3'>
												<Col>
													<Form.Select aria-label="Font" value={selectedBadgeLine?.font || ''} onChange={e => handleLabelsChange('font', e.target.value)}>
														<option value="">-select-</option>
														<optgroup label='System Fonts'>
														{PrintFonts.filter(f => f.group === 'SYSTEM').map(font => {
															return (
																<option value={font.value}>{font.label}</option>
															)
														})}
														</optgroup>
														<optgroup label='Chinese Fonts'>
														{PrintFonts.filter(f => f.group === 'CHINESE').map(font => {
															return (
																<option value={font.value}>{font.label}</option>
															)
														})}
														</optgroup>
													</Form.Select>
												</Col>
												<Col>
													<Form.Select aria-label="Font Size" value={selectedBadgeLine?.font_size || ''} onChange={e => handleLabelsChange('font_size', e.target.value)}>
														<option value="">-select-</option>
														{PrintFontSizes.map(size => {
															return (
																<option value={size}>{size}</option>
															)
														})}
													</Form.Select>
												</Col>
											</Row>
											<Row className='mb-3'>
												<Col>
													<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
														<button type='button' onClick={e => handleLabelsChange('is_bold', (selectedBadgeLine?.is_bold === 1) ? 0 : 1)}>
															{selectedBadgeLine?.is_bold === 1 ? (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#2C292C" stroke="#2C292C" />
																	<path d="M18.2223 28V16.3636H22.8814C23.7375 16.3636 24.4515 16.4905 25.0234 16.7443C25.5954 16.9981 26.0253 17.3504 26.3132 17.8011C26.6011 18.2481 26.745 18.7633 26.745 19.3466C26.745 19.8011 26.6541 20.2008 26.4723 20.5455C26.2905 20.8864 26.0405 21.1667 25.7223 21.3864C25.4079 21.6023 25.0481 21.7557 24.6428 21.8466V21.9602C25.0859 21.9792 25.5007 22.1042 25.8871 22.3352C26.2772 22.5663 26.5935 22.8902 26.8359 23.3068C27.0784 23.7197 27.1996 24.2121 27.1996 24.7841C27.1996 25.4015 27.0462 25.9527 26.7393 26.4375C26.4363 26.9186 25.9875 27.2992 25.3928 27.5795C24.7981 27.8598 24.0651 28 23.1939 28H18.2223ZM20.6825 25.9886H22.6882C23.3738 25.9886 23.8738 25.858 24.1882 25.5966C24.5026 25.3314 24.6598 24.9792 24.6598 24.5398C24.6598 24.2178 24.5821 23.9337 24.4268 23.6875C24.2715 23.4413 24.05 23.2481 23.7621 23.108C23.478 22.9678 23.139 22.8977 22.745 22.8977H20.6825V25.9886ZM20.6825 21.233H22.5064C22.8435 21.233 23.1428 21.1742 23.4041 21.0568C23.6693 20.9356 23.8776 20.7652 24.0291 20.5455C24.1844 20.3258 24.2621 20.0625 24.2621 19.7557C24.2621 19.3352 24.1125 18.9962 23.8132 18.7386C23.5178 18.4811 23.0973 18.3523 22.5518 18.3523H20.6825V21.233Z" fill="#FFFFFF" />
																</svg>
															) : (
															<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white" stroke="#ACA6A6" />
																<path d="M18.2223 28V16.3636H22.8814C23.7375 16.3636 24.4515 16.4905 25.0234 16.7443C25.5954 16.9981 26.0253 17.3504 26.3132 17.8011C26.6011 18.2481 26.745 18.7633 26.745 19.3466C26.745 19.8011 26.6541 20.2008 26.4723 20.5455C26.2905 20.8864 26.0405 21.1667 25.7223 21.3864C25.4079 21.6023 25.0481 21.7557 24.6428 21.8466V21.9602C25.0859 21.9792 25.5007 22.1042 25.8871 22.3352C26.2772 22.5663 26.5935 22.8902 26.8359 23.3068C27.0784 23.7197 27.1996 24.2121 27.1996 24.7841C27.1996 25.4015 27.0462 25.9527 26.7393 26.4375C26.4363 26.9186 25.9875 27.2992 25.3928 27.5795C24.7981 27.8598 24.0651 28 23.1939 28H18.2223ZM20.6825 25.9886H22.6882C23.3738 25.9886 23.8738 25.858 24.1882 25.5966C24.5026 25.3314 24.6598 24.9792 24.6598 24.5398C24.6598 24.2178 24.5821 23.9337 24.4268 23.6875C24.2715 23.4413 24.05 23.2481 23.7621 23.108C23.478 22.9678 23.139 22.8977 22.745 22.8977H20.6825V25.9886ZM20.6825 21.233H22.5064C22.8435 21.233 23.1428 21.1742 23.4041 21.0568C23.6693 20.9356 23.8776 20.7652 24.0291 20.5455C24.1844 20.3258 24.2621 20.0625 24.2621 19.7557C24.2621 19.3352 24.1125 18.9962 23.8132 18.7386C23.5178 18.4811 23.0973 18.3523 22.5518 18.3523H20.6825V21.233Z" fill="#2C292C" />
															</svg>
															)}
														</button>
														<button type='button' onClick={e => handleLabelsChange('is_italic', (selectedBadgeLine?.is_italic === 1) ? 0 : 1)}>
															{selectedBadgeLine?.is_italic === 1 ? (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#2C292C" stroke="#2C292C" />
																	<path d="M24.4055 16.3636L22.4737 28H21.0646L22.9964 16.3636H24.4055Z" fill="#FFFFFF" />
																</svg>
															) : (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white" stroke="#ACA6A6" />
																	<path d="M24.4055 16.3636L22.4737 28H21.0646L22.9964 16.3636H24.4055Z" fill="#2C292C" />
																</svg>
															)}
														</button>
														<button type='button' onClick={e => handleLabelsChange('is_underline', (selectedBadgeLine?.is_underline === 1) ? 0 : 1)}>
															{selectedBadgeLine?.is_underline === 1 ? (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#2C292C" stroke="#2C292C" />
																	<path d="M24.8629 16.3636H26.6243V24.017C26.6243 24.8314 26.433 25.553 26.0504 26.1818C25.6679 26.8068 25.13 27.2992 24.4368 27.6591C23.7436 28.0152 22.9311 28.1932 21.9993 28.1932C21.0713 28.1932 20.2607 28.0152 19.5675 27.6591C18.8743 27.2992 18.3364 26.8068 17.9538 26.1818C17.5713 25.553 17.38 24.8314 17.38 24.017V16.3636H19.1357V23.875C19.1357 24.4015 19.2512 24.8693 19.4822 25.2784C19.7171 25.6875 20.0485 26.0095 20.4766 26.2443C20.9046 26.4754 21.4122 26.5909 21.9993 26.5909C22.5902 26.5909 23.0997 26.4754 23.5277 26.2443C23.9595 26.0095 24.2891 25.6875 24.5163 25.2784C24.7474 24.8693 24.8629 24.4015 24.8629 23.875V16.3636Z" fill="#FFFFFF" />
																	<path d="M16.1016 31.1818H27.9027V32.2727H16.1016V31.1818Z" fill="#FFFFFF" />
																</svg>
															) : (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white" stroke="#ACA6A6" />
																	<path d="M24.8629 16.3636H26.6243V24.017C26.6243 24.8314 26.433 25.553 26.0504 26.1818C25.6679 26.8068 25.13 27.2992 24.4368 27.6591C23.7436 28.0152 22.9311 28.1932 21.9993 28.1932C21.0713 28.1932 20.2607 28.0152 19.5675 27.6591C18.8743 27.2992 18.3364 26.8068 17.9538 26.1818C17.5713 25.553 17.38 24.8314 17.38 24.017V16.3636H19.1357V23.875C19.1357 24.4015 19.2512 24.8693 19.4822 25.2784C19.7171 25.6875 20.0485 26.0095 20.4766 26.2443C20.9046 26.4754 21.4122 26.5909 21.9993 26.5909C22.5902 26.5909 23.0997 26.4754 23.5277 26.2443C23.9595 26.0095 24.2891 25.6875 24.5163 25.2784C24.7474 24.8693 24.8629 24.4015 24.8629 23.875V16.3636Z" fill="#2C292C" />
																	<path d="M16.1016 31.1818H27.9027V32.2727H16.1016V31.1818Z" fill="#2C292C" />
																</svg>
															)}
														</button>
													</div>
												</Col>
												<Col>
													<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
														<button type='button' onClick={e => handleLabelsChange('text_align', 'left')}>
															{selectedBadgeLine?.text_align === 'left' ? (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#2C292C" stroke="#2C292C" />
																	<rect x="16" y="17.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="16" y="25.5" width="8" height="1" rx="0.5" fill="#FFFFFF" />
																</svg>
															) : (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white" stroke="#ACA6A6" />
																	<rect x="16" y="17.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="16" y="25.5" width="8" height="1" rx="0.5" fill="#ACA6A6" />
																</svg>
															)}
														</button>
														<button type='button' onClick={e => handleLabelsChange('text_align', 'center')}>
															{selectedBadgeLine?.text_align === 'center' ? (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#2C292C" stroke="#2C292C" />
																	<rect x="18" y="17.5" width="8" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="18" y="25.5" width="8" height="1" rx="0.5" fill="#FFFFFF" />
																</svg>
															) : (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white" stroke="#ACA6A6" />
																	<rect x="18" y="17.5" width="8" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="18" y="25.5" width="8" height="1" rx="0.5" fill="#ACA6A6" />
																</svg>
															)}
														</button>
														<button type='button' onClick={e => handleLabelsChange('text_align', 'right')}>
															{selectedBadgeLine?.text_align === 'right' ? (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#2C292C" stroke="#2C292C" />
																	<rect x="16" y="17.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="20" y="25.5" width="8" height="1" rx="0.5" fill="#FFFFFF" />
																</svg>
															) : (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white" stroke="#ACA6A6" />
																	<rect x="16" y="17.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="20" y="25.5" width="8" height="1" rx="0.5" fill="#ACA6A6" />
																</svg>
															)}
														</button>
													</div>
												</Col>
											</Row>
										</div>
										</>
									) : (selectedBadgeLine.data_type === 'QR') ? (
										<>
										<div className="toolbox-container">
											<h5>QR Code Content</h5>

											<Row className='mb-3'>
												<Col>
													<Form.Select aria-label="Field" value={selectedBadgeLine?.guest_field || ''} onChange={e => handleLabelsChange('guest_field', e.target.value)}>
														<option value="">None</option>
														<optgroup label='Fields'>
														{eventDataFields.filter(field => field.field.indexOf('|') === -1).map(field => {
															return (
																<option value={field.field}>{field.display_name}</option>
															)
														})}
														</optgroup>
														<optgroup label='Combined Fields'>
														{eventDataFields.filter(field => field.field.indexOf('|') >= 0).map(field => {
															return (
																<option value={field.field}>{field.display_name}</option>
															)
														})}
														</optgroup>
													</Form.Select>
												</Col>
											</Row>
											<Row className='mb-3'>
												<Col>
													<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
														<button type='button' onClick={e => handleLabelsChange('text_align', 'left')}>
															{selectedBadgeLine?.text_align === 'left' ? (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#2C292C" stroke="#2C292C" />
																	<rect x="16" y="17.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="16" y="25.5" width="8" height="1" rx="0.5" fill="#FFFFFF" />
																</svg>
															) : (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white" stroke="#ACA6A6" />
																	<rect x="16" y="17.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="16" y="25.5" width="8" height="1" rx="0.5" fill="#ACA6A6" />
																</svg>
															)}
														</button>
														<button type='button' onClick={e => handleLabelsChange('text_align', 'center')}>
															{selectedBadgeLine?.text_align === 'center' ? (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#2C292C" stroke="#2C292C" />
																	<rect x="18" y="17.5" width="8" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="18" y="25.5" width="8" height="1" rx="0.5" fill="#FFFFFF" />
																</svg>
															) : (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white" stroke="#ACA6A6" />
																	<rect x="18" y="17.5" width="8" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="18" y="25.5" width="8" height="1" rx="0.5" fill="#ACA6A6" />
																</svg>
															)}
														</button>
														<button type='button' onClick={e => handleLabelsChange('text_align', 'right')}>
															{selectedBadgeLine?.text_align === 'right' ? (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="#2C292C" stroke="#2C292C" />
																	<rect x="16" y="17.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#FFFFFF" />
																	<rect x="20" y="25.5" width="8" height="1" rx="0.5" fill="#FFFFFF" />
																</svg>
															) : (
																<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="43" height="43" rx="7.5" fill="white" stroke="#ACA6A6" />
																	<rect x="16" y="17.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="16" y="21.5" width="12" height="1" rx="0.5" fill="#ACA6A6" />
																	<rect x="20" y="25.5" width="8" height="1" rx="0.5" fill="#ACA6A6" />
																</svg>
															)}
														</button>
													</div>
												</Col>
												<Col></Col>
											</Row>
										</div>
										</>
									) : null}

									<div>
										<Button variant='danger' size='lg' onClick={removeElement}>
											<i>
												<svg width="20" height="20" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M8.8 3.4V2.92C8.8 2.24794 8.8 1.91191 8.66921 1.65521C8.55416 1.42942 8.37058 1.24584 8.14479 1.13079C7.88809 1 7.55206 1 6.88 1H5.92C5.24794 1 4.91191 1 4.65521 1.13079C4.42942 1.24584 4.24584 1.42942 4.13079 1.65521C4 1.91191 4 2.24794 4 2.92V3.4M5.2 6.7V9.7M7.6 6.7V9.7M1 3.4H11.8M10.6 3.4V10.12C10.6 11.1281 10.6 11.6321 10.4038 12.0172C10.2312 12.3559 9.95587 12.6312 9.61718 12.8038C9.23214 13 8.72809 13 7.72 13H5.08C4.07191 13 3.56786 13 3.18282 12.8038C2.84413 12.6312 2.56876 12.3559 2.39619 12.0172C2.2 11.6321 2.2 11.1281 2.2 10.12V3.4" 
													stroke="#FFFFFF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</i>
										</Button>
									</div>
								</>
							) : null}
							

							<div style={{
								// display: 'flex',
								// justifyContent: 'center',
								padding: '20px',
								textAlign: 'center'
							}}>
								<Button className={`cp_blk_btn ${allChangesSaved ? 'all-changes-save' : ''}`} variant='dark' onClick={updateLabels} disabled={allChangesSaved}>Save</Button>
								

								<div className='mt-3'>
									<Button type='button' variant='dark' disabled={!allChangesSaved} onClick={() => setShowPreviewPopup(true)}>Generate Preview</Button>
								</div>
							</div>

						</Col>
					</Row>
				</div>
			</div>

			{showPreviewPopup && (
				<BadgePreviewPopup 
					eventId={activeEvent.id}
					isOpen={showPreviewPopup}
					setIsOpen={setShowPreviewPopup}
				/>
			)}
		</>
	);
};

export default BadgeCreator;