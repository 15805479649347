import React, { useEffect, useState } from 'react';
import { EventDataField, GuestList, RsvpForm, RsvpFormType } from 'types';
import { Button, ButtonGroup, Col, Form, InputGroup, Modal, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RSVP_BASE_URL, RsvpFormTypes } from 'constant';
import { apiGetGuestLists } from 'api/guestLists';
import { apiCheckRsvpFormsSlug, apiCreateRsvpForm } from 'api/rsvpForms';
import { apiGetEventDataFields } from 'api/events';


type CreatePopupProps = {
	eventId: number | string;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	onCreated: (form: RsvpForm) => void;
}

const CreatePopup = ({ eventId, isOpen, setIsOpen, onCreated }: CreatePopupProps) => {
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [isValidSlug, setIsValidSlug] = useState(false);
	const [lists, setLists] = useState<GuestList[]>([]);
	const [eventDataFields, setEventDataFields] = useState<EventDataField[]>([]);
	const [type, setType] = useState<RsvpFormType>(RsvpFormTypes.PUBLIC);
	const [name, setName] = useState<string>('')
	const [slug, setSlug] = useState<string>('')
	const [listId, setListId] = useState<number | string>('')
	const [step, setStep] = useState<number>(1);
	const [selectedFields, setSelectedFields] = useState<number[]>([]);

	useEffect(() => {
		(async () => {
			if (eventId) {
				const response = await apiGetGuestLists(eventId as number)

				if (response.success) {
					setLists(response.data.lists)
					if (response.data.lists.length && response.data.lists[0].id) {
						setListId(response.data.lists[0].id)
					}
				}

				const responseFields = await apiGetEventDataFields(eventId);
				if (responseFields.success) {
					setEventDataFields([...responseFields.data]);
				}
			}
		})()
	}, [eventId])

	useEffect(() => {
		setSlug(name.replace(/\s+/g, '').replace(/[^\w-]+/g, '').toLowerCase())
	}, [name])

	const checkSlug = async () => {
		if (slug && eventId) {
			const response = await apiCheckRsvpFormsSlug(eventId as number, slug)

			if (response.success) {
				setIsValidSlug(true)
				return;
			}
		}
		setIsValidSlug(false)
	}

	const handleSubmit = async () => {
		if (step < 2) {
			setStep(step + 1);
			return;
		}

		batch(() => {
			setErrMsg('');
			setIsLoading(true);
		})

		const response = await apiCreateRsvpForm(eventId, {
			guest_list_id: listId,
			type,
			name,
			slug,
		});
		setIsLoading(false);
		if (response.success) {
			onCreated(response.data)
		} else {
			setErrMsg(response.message)
		}
	}

	return (
		<>
			<Modal show={isOpen} className="createRsvpFormModal" onHide={() => setIsOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='m-0'>Create RSVP Form <small>(Step {step}/2)</small></h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{errMsg && <div className="alert alert-danger">{errMsg}</div>}

					{(step === 1) ? (
						<>	
							<Form.Group className="mb-3">
								<Form.Label>Form name (internal)</Form.Label>
								<Form.Control 
									type="text" 
									value={name} 
									onChange={e => setName(e.target.value)} 
									onBlur={checkSlug}
								/>
							</Form.Group>
							
							<Row>
								<Col></Col>
								<Col sm={8}>
									<Form.Group className="mb-3">
										<div>
											<ToggleButtonGroup 
												type="radio" 
												name="template-types" 
												defaultValue={RsvpFormTypes.PUBLIC} 
												value={type} 
												onChange={(e) => setType(e)} style={{ display: 'flex' }}
											>
												<ToggleButton id="tbg-radio-1" variant='outline-dark' 
													value={RsvpFormTypes.PUBLIC} 
													style={{ height: 50, lineHeight: '35px' }}
												>
													Public
												</ToggleButton>
												<ToggleButton id="tbg-radio-2" variant='outline-dark' 
													value={RsvpFormTypes.PRIVATE} 
													style={{ height: 50, lineHeight: '35px' }}
												>
													Private
												</ToggleButton>
											</ToggleButtonGroup>
										</div>
									</Form.Group>
								</Col>
								<Col></Col>
							</Row>

							<Form.Group className="mb-3">
								<Form.Label>Add to guest list <small>(new form submissions are added to this list)</small></Form.Label>
								<Form.Select value={listId} onChange={e => setListId(e.target.value)}>
									{lists.map((list, index) => {
										return <option key={index} value={(list.id) ? list.id : ''}>{list.list_name}</option>
									})}
								</Form.Select>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Customize Form URL</Form.Label>
								<InputGroup>
									<InputGroup.Text>{RSVP_BASE_URL}/</InputGroup.Text>
									<Form.Control 
										type="text" 
										placeholder="" 
										value={slug} 
										isValid={isValidSlug}
										isInvalid={!isValidSlug}
										onChange={e => setSlug(e.target.value)} 
										onBlur={checkSlug}
									/>
								</InputGroup>
								
							</Form.Group>
						</>
					) : (
						<>
							<Form.Label>Choose data fields for your RSVP form <small>(You can change this later)</small></Form.Label>
							<div style={{
								maxHeight: 300,
								overflow: 'auto'
							}}>
							<table className='table table-striped'>
								<thead>
									<tr>
										<th>Display Name</th>
										<th>Type</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
								{eventDataFields.map((field, i) => {
									return (
										<tr key={field.id}>
											<td
											onClick={() => {
												if (selectedFields.includes(field.id)) {
													setSelectedFields([...selectedFields.filter(id => id !== field.id)])
												} else {
													setSelectedFields([...selectedFields, field.id])
												}
											}}
											>{field.display_name}</td>
											<td
											onClick={() => {
												if (selectedFields.includes(field.id)) {
													setSelectedFields([...selectedFields.filter(id => id !== field.id)])
												} else {
													setSelectedFields([...selectedFields, field.id])
												}
											}}
											>{field.type}</td>
											<td>
											<Form.Group>
												<Form.Check 
													className="" 
													type="checkbox" 
													name="rsvp-form-field" 
													checked={selectedFields.includes(field.id)} 
													label="" 
													onChange={() => {
														if (selectedFields.includes(field.id)) {
															setSelectedFields([...selectedFields.filter(id => id !== field.id)])
														} else {
															setSelectedFields([...selectedFields, field.id])
														}
													}} />
											</Form.Group>
											</td>
										</tr>
									)
								})}
								</tbody>
							</table>
							</div>
						</>
					)}

				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setIsOpen(false)} style={{ width: 'auto', flexGrow: 1, padding: '1rem' }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleSubmit} style={{ width: 'auto', flexGrow: 1.8, padding: '1rem' }} disabled={isLoading || !isValidSlug}>
						<div className='d-flex align-items-center justify-content-between'>
							<span>Next</span>
							<span className="arrow">
								<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}


export default CreatePopup;