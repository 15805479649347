import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { EmailTemplate, EmailTemplateType, EventDataField } from 'types';
import { ButtonGroup, Form, InputGroup, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { EmailTemplateTypes } from 'constant';
import { batch } from 'react-redux';
import { apiCreateTemplate } from 'api/emailTemplate';
import { apiGetEventDataFields } from 'api/events';


type TemplatePlaceholderPopupProps = {
	eventId: number | string;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

const PlaceholderField = ({data}: {data: string}) => {
	const [value, setValue] = useState<string>(data);

	useEffect(() => {
		setValue(data)
	}, [data])

	const copyText = () => {
		navigator.clipboard.writeText(data)
		setValue('copied')
		setTimeout(() => {
			setValue(data)
		}, 1000)
	}

	return (
		<>
		<InputGroup className="mb-1" size='sm'>
			<Form.Control
				value={value}
				disabled
			/>
			<Button variant="outline-dark" 
			onClick={copyText}
			>
			copy
			</Button>
		</InputGroup>
		</>
	)
}

const TemplatePlaceholderPopup = ({ eventId, isOpen, setIsOpen }: TemplatePlaceholderPopupProps) => {
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState(true);
	const [eventDataFields, setEventDataFields] = useState<EventDataField[]>([]);
	const [isQR, setIsQR] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			if (eventId) {
				
				const fieldResponse = await apiGetEventDataFields(eventId);
				setIsLoading(false)

				if (fieldResponse.success) {
					setEventDataFields(fieldResponse.data);
				}
			}
		})()
	}, [eventId])

	return (
		<>
			<Modal show={isOpen} className="templatePlaceholderModal" onHide={() => setIsOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
					Template Placeholders
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{errMsg && <div className="alert alert-danger">{errMsg}</div>}
					
					{(isLoading) ? (
						<>
						<div className="pt-5 text-center pb-5">
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</div>
						</>
					) : (
						<>
						<Row>
							<Col><p className='m-0'>Guest Fields</p></Col>
							<Col className='text-end'>
								<div className='d-flex align-items-end justify-content-end'>
								<Form.Check id="is-qr-check" checked={isQR} onChange={() => setIsQR(!isQR)} label='View QR Codes' />
								</div>
							</Col>
						</Row>
						
						<PlaceholderField data={`[${isQR ? 'QR' : 'Guest'}](Unique ID)`} />
						{eventDataFields.map(field => {
							return (<PlaceholderField key={field.id} data={`[${isQR ? 'QR' : 'Guest'}](${field.display_name})`} />)
						})}

						<p className='m-0 mt-2'>Event</p>
						<PlaceholderField data={`[Event](Name)`} />
						<PlaceholderField data={`[Event](Date)`} />
						<PlaceholderField data={`[Event](Time)`} />
						<PlaceholderField data={`[Event](Location)`} />

						<p className='m-0 mt-2'>Template</p>
						<PlaceholderField data={`[Template](Sender Name)`} />
						<PlaceholderField data={`[Template](Sender Email)`} />
						<PlaceholderField data={`[Template](Sender Reply-to email)`} />
						</>
					)}
				</Modal.Body>
			</Modal>
		</>
	)
}

export default TemplatePlaceholderPopup;