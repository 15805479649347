import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, DropdownDivider, Modal, Row, Table } from 'react-bootstrap';
import { batch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setHeaderTitle } from 'redux/interfaceManger';
import { EmailTemplate, RsvpForm } from 'types';
import { apiCopyRsvpForm, apiDeleteRsvpForm, apiGetRsvpForms } from 'api/rsvpForms';
import CreatePopup from './CreatePopup';
import { setEditEvent } from 'redux/eventSlice';
import { BASE_URL, RSVP_BASE_URL } from 'constant';
import { toast } from 'react-toastify';
import FormSettingsPopup from './FormBuilder/FormSettingsPopup';

type FormComponentProps = {
	form: RsvpForm
	handleCopyForm: (form: RsvpForm) => void;
	setConfirmFormDelete: (form: RsvpForm | null) => void;
	setReloadList:  React.Dispatch<React.SetStateAction<number>>;
}

const FormComponent = ({ form, handleCopyForm, setConfirmFormDelete, setReloadList }: FormComponentProps) => {
	const [settingsPopupOpen, setSettingsPopupOpen] = useState<boolean>(false);
	const navigate = useNavigate();

	return (
		<div className='rsvp-box'>
			<div className='rsvp-box-icon'>
				{(form.type === 'PUBLIC') ? (
					<svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="24" height="24" fill="transparent"/>
						<path 
							fill-rule="evenodd" 
							clip-rule="evenodd" 
							d="M9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H9ZM9 14C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16H15C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14H9ZM12.482 1.99989C13.1608 1.99885 13.7632 1.99793 14.3196 2.2284C14.876 2.45887 15.3014 2.88551 15.7806 3.36624C16.7302 4.31875 17.6813 5.26983 18.6338 6.21942C19.1145 6.69867 19.5412 7.12401 19.7716 7.68041C20.0021 8.23682 20.0012 8.83926 20.0001 9.51807C19.9963 12.034 20 14.5499 20 17.0659C20.0001 17.9524 20.0001 18.7162 19.9179 19.3278C19.8297 19.9833 19.631 20.6117 19.1213 21.1214C18.6117 21.631 17.9833 21.8298 17.3278 21.9179C16.7161 22.0001 15.9523 22.0001 15.0658 22H8.9342C8.0477 22.0001 7.28388 22.0001 6.67221 21.9179C6.0167 21.8298 5.38835 21.631 4.87868 21.1214C4.36902 20.6117 4.17028 19.9833 4.08215 19.3278C3.99991 18.7162 3.99995 17.9524 4 17.0659L4.00001 7.00004C4.00001 6.97802 4 6.95607 4 6.93421C3.99995 6.04772 3.99991 5.28391 4.08215 4.67224C4.17028 4.01673 4.36902 3.38838 4.87869 2.87872C5.38835 2.36905 6.0167 2.17031 6.67221 2.08218C7.28387 1.99994 8.04769 1.99998 8.93418 2.00003C10.1168 2.0001 11.2994 2.00171 12.482 1.99989Z" 
							fill="#0d6efd"
						/>
					</svg>
				) : (
					<svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path 
							d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288" 
							stroke="#0d6efd" 
							stroke-width="2" 
							stroke-linecap="round" 
							stroke-linejoin="round"
						/>
					</svg>
				)}
			</div>
			<div className='rsvp-box-text'>
				<p>{form.name}</p>
				{/* <div className='small text-muted'>1 response</div> */}
			</div>
			<div className='rsvp-box-button'>
				<DropdownButton
					title=''
					id="dropdown-menu-align-responsive-1"
					align={{ lg: 'end' }}
				>
					<Dropdown.Item onClick={() => navigate(`/rsvp/${form.id}/form-builder`, {state: form})}>
						Edit
					</Dropdown.Item>
					<a href={`${RSVP_BASE_URL}/${form.slug}`} target='_blank' rel="noreferrer" className='dropdown-item'>
						<div className='d-flex align-items-center justify-content-between'>
							<div>Go to form</div>
							<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 48 48">
								<path d="M 35.478516 5.9804688 A 2.0002 2.0002 0 0 0 34.085938 9.4140625 L 35.179688 10.507812 C 23.476587 10.680668 14 20.256715 14 32 A 2.0002 2.0002 0 1 0 18 32 C 18 22.427546 25.627423 14.702715 35.154297 14.517578 L 34.085938 15.585938 A 2.0002 2.0002 0 1 0 36.914062 18.414062 L 41.236328 14.091797 A 2.0002 2.0002 0 0 0 41.228516 10.900391 L 36.914062 6.5859375 A 2.0002 2.0002 0 0 0 35.478516 5.9804688 z M 12.5 6 C 8.9338464 6 6 8.9338464 6 12.5 L 6 35.5 C 6 39.066154 8.9338464 42 12.5 42 L 35.5 42 C 39.066154 42 42 39.066154 42 35.5 L 42 28 A 2.0002 2.0002 0 1 0 38 28 L 38 35.5 C 38 36.903846 36.903846 38 35.5 38 L 12.5 38 C 11.096154 38 10 36.903846 10 35.5 L 10 12.5 C 10 11.096154 11.096154 10 12.5 10 L 20 10 A 2.0002 2.0002 0 1 0 20 6 L 12.5 6 z" fill='#2C292C'></path>
							</svg>
						</div>
					</a>
					<Dropdown.Item onClick={() => handleCopyForm(form)}>
						Copy
					</Dropdown.Item>
					{/* <Link to={`/rsvp/${form.id}/responses`} className='dropdown-item'>
						View Responses
					</Link> */}
					<DropdownDivider />
					<Dropdown.Item onClick={() => setSettingsPopupOpen(true)}>
						Close Form
					</Dropdown.Item>
					<Dropdown.Item className='text-danger' onClick={() => setConfirmFormDelete(form)}>
						Delete
					</Dropdown.Item>
				</DropdownButton>
			</div>

			{(settingsPopupOpen && form) && (
				<FormSettingsPopup eventId={form.event_id} form={form} isOpen={settingsPopupOpen} setIsOpen={setSettingsPopupOpen} onUpdate={(data) => {
					setReloadList(prev => prev + 1)
				}} />
			)}
		</div>
	)
}

const RsvpFormsList = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const [forms, setForms] = useState<RsvpForm[]>([]);
	const [createPopupOpen, setCreatePopupOpen] = useState<boolean>(false);
	const [confirmFormDelete, setConfirmFormDelete] = useState<RsvpForm | null>(null);
	const [reloadList, setReloadList] = useState<number>(0);

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('RSVP Forms & Pages'))
		})
	}, [])

	useEffect(() => {
		(async () => {
			if (activeEvent) {
				const response = await apiGetRsvpForms(activeEvent.id)
	
				if (response.success) {
					setForms(response.data)
				}
			}
		})();
	}, [activeEvent, reloadList])

	const onFormCreate = (newForm: RsvpForm) => {
		batch(() => {
			setForms([newForm, ...forms])
			setCreatePopupOpen(false);
		})
	}

	const handleCopyForm = async (form: RsvpForm) => {
		if (activeEvent) {
			const response = await apiCopyRsvpForm(activeEvent.id, form.id)
		
			if (response.success) {
				setForms([response.data, ...forms])
			} else {
				toast.error(response.message)
			}
		}
	}

	const handleDeleteForm = async (form: RsvpForm) => {
		if (activeEvent) {
			const response = await apiDeleteRsvpForm(activeEvent.id, form.id)
		
			if (response.success) {
				batch(() => {
					setForms([...forms.filter(f => f.id !== form.id)])
					setConfirmFormDelete(null)
				})
			} else {
				toast.error(response.message)
			}
		}
	}

	if (!activeEvent) {
		return <Navigate to="/" />
	}

	return (
		<>
			<div className='page-rsvp-list'>
				<div className='mb-4'>
					<Row>
						<Col className=''>
							<Button variant='dark' onClick={() => setCreatePopupOpen(true)}>
								<i className="ico">
									<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M7 1V13M1 7H13" stroke="#FFFFFF" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</i>
								Create Form
							</Button>
						</Col>
					</Row>
				</div>
				

				<div className=''>
					<p className='text-muted'>ALL FORMS</p>

					{(forms.length === 0) && (
						<p className='text-center small text-muted'>No forms available!</p>
					)}

					<div className='d-flex align-items-stretch justify-content-start flex-wrap gap-3'>
						{forms.map(form => {
							return <FormComponent key={form.id} form={form} handleCopyForm={handleCopyForm} setConfirmFormDelete={setConfirmFormDelete} setReloadList={setReloadList} />
						})}
					</div>
				</div>
			</div>

			{(createPopupOpen) && (
				<CreatePopup eventId={activeEvent.id} isOpen={createPopupOpen} setIsOpen={setCreatePopupOpen} onCreated={onFormCreate} />
			)}

			{(confirmFormDelete !== null) && (
				<Modal aria-labelledby="contained-modal-title-vcenter" centered show={confirmFormDelete !== null} onHide={() => setConfirmFormDelete(null)} className='deleteModal'>
					<Modal.Header closeButton>
						<Modal.Title>Delete RSVP Form</Modal.Title>
					</Modal.Header>

					<Modal.Body>Are you sure you want to delete <strong>[{confirmFormDelete.name}]</strong> form?</Modal.Body>

					<Modal.Footer>
						<Button variant="secondary" onClick={() => setConfirmFormDelete(null)}>
							Cancel
						</Button>

						<Button variant="danger" onClick={() => handleDeleteForm(confirmFormDelete)}>
							Yes, delete form
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
};

export default RsvpFormsList;