import React, { Routes, Route } from 'react-router-dom';

import AdminLayout from 'layout/AdminLayout';

import Dashboard from 'views/Admin/Dashboard';
import UploadGuest from 'views/Admin/UploadGuest';
import GuestList from 'views/Admin/GuestList';
import GuestListView from 'views/Admin/GuestListView';
import Accounts from 'views/Admin/Accounts';
import Team from 'views/Admin/Team';
import TeamAdd from 'views/Admin/TeamAdd';
import BadgeCreator from 'views/Admin/BadgeCreator';
import DownloadGuests from 'views/Admin/DownloadGuests';
import DataFields from 'views/Admin/DataFields';
import EmailTemplates from 'views/Admin/EmailTemplate/index';
import EmailTemplateEditHTML from 'views/Admin/EmailTemplate/EditHTML';
import EmailTemplateEmailBuilder from 'views/Admin/EmailTemplate/EmailBuilder';
import EmailTemplatePreview from 'views/Admin/EmailTemplate/TemplatePreview';
import EmailTemplateReport from 'views/Admin/EmailTemplate/TemplateReport';
import Domain from 'views/Admin/Domain';
import DomainConfiguration from 'views/Admin/DomainConfiguration';
import RsvpForms from 'views/Admin/Rsvp/index';
import RsvpFormBuilder from 'views/Admin/Rsvp/FormBuilder/index';

import NotFound from 'views/NotFound';
import AcceptInvitationError from 'views/AcceptInvitationError';

const AdminRoutes = (): JSX.Element => {

	return (
		<Routes>
			<Route path="/" element={<AdminLayout />}>
				<Route element={<Dashboard />} path="/" />
				<Route element={<Dashboard />} path="/dashboard" />
				<Route element={<UploadGuest />} path="/upload-guest" />
				<Route element={<GuestList />} path="/guest-list" />
				<Route element={<GuestListView />} path="/guest-list/view" />
				<Route element={<GuestListView />} path="/guest-list/view/:listId" />
				<Route element={<Accounts />} path="/accounts" />
				<Route element={<Team />} path="/team" />
				<Route element={<TeamAdd />} path="/team/add" />
				<Route element={<BadgeCreator />} path="/setup-badge-printing" />
				<Route element={<DownloadGuests />} path="/download-guest" />
				<Route element={<DataFields />} path="/data-fields" />
				<Route element={<EmailTemplates />} path="/email-templates" />
				<Route element={<EmailTemplateEditHTML />} path="/email-templates/:templateId/edit-html" />
				<Route element={<EmailTemplateEmailBuilder />} path="/email-templates/:templateId/edit-builder" />
				<Route element={<EmailTemplatePreview />} path="/email-templates/:templateId/preview" />
				<Route element={<EmailTemplateReport />} path="/email-templates/:templateId/report" />
				<Route element={<RsvpForms />} path="/rsvp" />
				<Route element={<RsvpFormBuilder />} path="/rsvp/:formId/form-builder" />
				<Route element={<DomainConfiguration />} path="/domain/configuration" />
				<Route element={<Domain />} path="/domain-sender-success" />
				<Route element={<Domain />} path="/domain-sender-error" />
				<Route element={<Domain />} path="/domains" />
				<Route element={<AcceptInvitationError />} path="/accept-invitation/:token" />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	)
}

export default AdminRoutes;