import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { EmailTemplate, EmailTemplateStatus, EmailTemplateType, GuestList, NumericFlag, RsvpForm, RsvpFormType } from 'types';
import { Alert, ButtonGroup, Form, InputGroup, Nav, Tab, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { batch } from 'react-redux';
import { apiCreateTemplate, apiSendTestEmails, apiUpdateTemplate } from 'api/emailTemplate';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { apiGetGuestByGuestLists, apiGetGuestsByEvent } from 'api/guests';
import { MultiValue } from 'react-select';
import { apiGetTeam } from 'api/team';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/hooks';
import { GuestStatus, RSVP_BASE_URL, RsvpFormTypes, TimeZones } from 'constant';
import { apiGetGuestLists } from 'api/guestLists';
import { apiCheckRsvpFormsSlug, apiUpdateRsvpForm } from 'api/rsvpForms';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';

type FormSettingsPopupProps = {
	eventId: number | string;
	form: RsvpForm
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	onUpdate: (data: RsvpForm) => void;
}


const modules = {
	toolbar: [
		[
			{ 'font': [] }, { 'size': [ 'small', false, 'large', ] },
			'bold', 'italic', 'underline', 'strike', 
		],[
			{ 'align': [] }, { 'color': [] },
			{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' },
			'link', 'clean'
		],
	],
}

const formats = [
	'font', 'size',
	'bold', 'italic', 'underline', 'strike', 
	'align', 'color', 'list', 'bullet', 'indent',
	'link'
]


const FormSettingsPopup = ({ eventId, form, isOpen, setIsOpen, onUpdate }: FormSettingsPopupProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isValidSlug, setIsValidSlug] = useState(true);
	const [lists, setLists] = useState<GuestList[]>([]);
	const [name, setName] = useState<string>(form.name)
	const [type, setType] = useState<RsvpFormType>(form.type);
	const [slug, setSlug] = useState<string>(form.slug)
	const [defaultStatus, setDefaultStatus] = useState<string>(form.default_status)
	const [guestListId, setListId] = useState<number | string>(form.guest_list_id)
	const [hasUniqueEmails, setHasUniqueEmails] = useState<NumericFlag>(form.has_unique_emails)
	const [isClosed, setIsClosed] = useState<NumericFlag>(form.is_closed)
	const [hasCloseTime, setHasCloseTime] = useState<NumericFlag>(form.has_close_time)
	const [closeDate, setCloseDate] = useState<string>(form.close_on_date)
	const [closeTime, setCloseTime] = useState<string>(form.close_on_time)
	const [closeTimeZone, setCloseTimeZone] = useState<string>(form.close_on_timezone)
	const [hasCloseResponseLimit, setHasCloseResponseLimit] = useState<NumericFlag>(form.has_close_response_limit)
	const [closeResponseLimit, setCloseResponseLimit] = useState<number>(form.close_response_limit || 20)
	const [closeMessage, setCloseMessage] = useState<string>(form.close_message || '<p class="ql-align-center"><br></p><p class="ql-align-center">This form is now closed.</p><p class="ql-align-center"><br></p><p class="ql-align-center"><br></p>')

	



	useEffect(() => {
		(async () => {
			if (eventId) {
				const response = await apiGetGuestLists(eventId as number)

				if (response.success) {
					setLists(response.data.lists)
				}
			}
		})()
	}, [eventId])


	const checkSlug = async () => {
		if (slug && eventId) {
			const response = await apiCheckRsvpFormsSlug(eventId as number, slug, form.id)

			if (response.success) {
				setIsValidSlug(true)
				return;
			}
		}
		setIsValidSlug(false)
	}

	const handleSubmit = async () => {

		const data: RsvpForm = {
			...form,
			name: name,
			type: type,
			slug: slug,
			default_status: defaultStatus,
			guest_list_id: guestListId as number,
			has_unique_emails: hasUniqueEmails,
			is_closed: isClosed,
			has_close_time: hasCloseTime,
			close_on_date: closeDate,
			close_on_time: closeTime,
			close_on_timezone: closeTimeZone,
			has_close_response_limit: hasCloseResponseLimit,
			close_response_limit: closeResponseLimit,
			close_message: closeMessage,
		}

		setIsLoading(true);
		console.log('updated data', data)
		const response = await apiUpdateRsvpForm(eventId, form.id, data);
		
		if (response.success) {
			onUpdate(response.data)
			toast.success(response.message)

			setTimeout(() => {
				setIsLoading(false);
				setIsOpen(false)
			}, 1000)
		} else {
			setIsLoading(false);
			toast.error(response.message)
		}
	}

	return (
		<>
			<Modal show={isOpen} className="createRsvpFormModal" size='xl' onHide={() => setIsOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='m-0'>Form Settings</h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body className='bg-light'>
					<Tab.Container id="left-tabs-example" defaultActiveKey="general">
						<Row>
							<Col sm={4}>
								<div className="">
									<Nav variant="pills" className="flex-column">
										<Nav.Item>
											<Nav.Link eventKey="general">General</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="closure">Form Closure Options</Nav.Link>
										</Nav.Item>
									</Nav>
								</div>
							</Col>
							<Col sm={8}>
								<div className="px-3 py-2 bg-white">
									<Tab.Content>
										<Tab.Pane eventKey="general">
											<Row>
												<Col>
													<Form.Group className="mb-3">
														<Form.Label>Form name (internal)</Form.Label>
														<Form.Control 
															type="text" 
															value={name} 
															onChange={e => setName(e.target.value)}
														/>
													</Form.Group>
												</Col>
												<Col>
													<Form.Group className="mb-3">
														<Form.Label>&nbsp;</Form.Label>
														<div>
															<ToggleButtonGroup 
																type="radio" 
																name="template-types" 
																defaultValue={RsvpFormTypes.PUBLIC} 
																value={type} 
																onChange={(e) => setType(e)} style={{ display: 'flex' }}
															>
																<ToggleButton id="tbg-radio-1" variant='outline-dark' 
																	value={RsvpFormTypes.PUBLIC} 
																>
																	Public
																</ToggleButton>
																<ToggleButton id="tbg-radio-2" variant='outline-dark' 
																	value={RsvpFormTypes.PRIVATE} 
																>
																	Private
																</ToggleButton>
															</ToggleButtonGroup>
														</div>
													</Form.Group>
												</Col>
											</Row>

											<Row>
												<Col md={8}>
													<Form.Group className="mb-3">
														<Form.Label>Add to guest list <small>(new form submissions are added to this list)</small></Form.Label>
														<Form.Select value={guestListId} onChange={e => setListId(e.target.value)}>
															{lists.map((list, index) => {
																return <option key={index} value={(list.id) ? list.id : ''}>{list.list_name}</option>
															})}
														</Form.Select>
													</Form.Group>
												</Col>
												<Col></Col>
											</Row>

											<Form.Label>Select the guest status that will be assigned upon form submission</Form.Label>
											<Row>
												<Col md={6}>
													<Form.Group className="mb-3">
														<Form.Select value={defaultStatus} onChange={e => setDefaultStatus(e.target.value)}>
															<option value={GuestStatus.CONFIRMED}>Confirmed</option>
															<option value={GuestStatus.UN_CONFIRMED}>Unconfirmed</option>
															<option value={GuestStatus.CANCELLED}>Cancelled</option>
															<option value={GuestStatus.CHECKED_IN}>Checked in</option>
														</Form.Select>
													</Form.Group>
												</Col>
												<Col></Col>
											</Row>
											
											<Row>
												<Col md={8}>
													<Form.Group className="mb-3">
														<Form.Label>Customize Form URL</Form.Label>
														<InputGroup>
															<InputGroup.Text>{RSVP_BASE_URL}/</InputGroup.Text>
															<Form.Control 
																type="text" 
																placeholder="" 
																value={slug} 
																isValid={isValidSlug}
																isInvalid={!isValidSlug}
																onChange={e => setSlug(e.target.value)} 
																onBlur={checkSlug}
															/>
														</InputGroup>
													</Form.Group>
												</Col>
												<Col></Col>
											</Row>
											
											<Row>
												<Col md={8}>
													<div className=''>
														<Alert color='info'>
															<Form.Check
																type="switch"
																id="unique-email-switch"
																label="Unique Email Registration"
																checked={hasUniqueEmails === 1}
																onChange={e => setHasUniqueEmails((hasUniqueEmails === 1) ? 0 : 1)}
															/>
															<p className='mt-2'>When enabled, each email address can only be used for a single registration. Toggle this option to prevent duplicate accounts.</p>
														</Alert>
													</div>
												</Col>
												<Col></Col>
											</Row>

										</Tab.Pane>
										<Tab.Pane eventKey="closure">
											
											<Row className='mb-4'>
												<Col>
													<Form.Label className='m-0'>Close this form now</Form.Label>
												</Col>
												<Col md={7}>
													{(isClosed === 1) ? <p className='text-danger text-end m-0'>Form is closed</p> : null}
												</Col>
												<Col md={1}>
													<Form.Check
														type="switch"
														id="is-closed-switch"
														label=""
														checked={isClosed === 1}
														onChange={e => setIsClosed((isClosed === 1) ? 0 : 1)}
													/>
												</Col>
											</Row>

											<Row className='mb-4'>
												<Col>
													<Form.Label className='m-0'>Schedule a close date</Form.Label>
												</Col>
												<Col md={7}>
													<InputGroup>
														<Form.Control type="date" min={(dayjs().format('YYYY-MM-DD'))} value={closeDate} onChange={e => setCloseDate(e.target.value)} />
														
														<Form.Control type="time" value={closeTime} onChange={e => setCloseTime(e.target.value)} />
													
														<Form.Select aria-label="Default select example" value={closeTimeZone} onChange={e => setCloseTimeZone(e.target.value)}>
															{TimeZones.map(timezone => {
																return (
																	<option key={timezone} value={timezone}>{timezone}</option>
																)
															})}
														</Form.Select>
													</InputGroup>
												</Col>
												<Col md={1}>
													<Form.Check
														type="switch"
														id="closed-schedule-switch"
														label=""
														checked={hasCloseTime === 1}
														onChange={e => setHasCloseTime((hasCloseTime === 1) ? 0 : 1)}
													/>
												</Col>
											</Row>

											<Row className='mb-4'>
												<Col md={7}>
													<Form.Label className='m-0'>Set a response limit</Form.Label>
												</Col>
												<Col md={4}>
													<Form.Control 
														type="number" 
														min="0"
														value={closeResponseLimit} 
														onChange={e => setCloseResponseLimit(parseInt(e.target.value))}
													/>
												</Col>
												<Col md={1}>
													<Form.Check
														type="switch"
														id="response-limit-switch"
														label=""
														checked={hasCloseResponseLimit === 1}
														onChange={e => setHasCloseResponseLimit((hasCloseResponseLimit === 1) ? 0 : 1)}
													/>
												</Col>
											</Row>

											<Row>
												<Col md={8}>
													<Form.Group className="mb-3">
														<Form.Label>Edit close message</Form.Label>
														<div className='form-control' style={{height: 100}}>
															<ReactQuill theme="bubble" modules={modules} formats={formats} value={closeMessage} onChange={setCloseMessage} />
														</div>
													</Form.Group>
												</Col>
											</Row>



										</Tab.Pane>
									</Tab.Content>
								</div>
							</Col>
						</Row>
					</Tab.Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setIsOpen(false)}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleSubmit} style={{ width: 100 }} disabled={isLoading || !isValidSlug}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default FormSettingsPopup;