import React, { useEffect, useRef, useState } from 'react';
import {useSortable} from '@dnd-kit/sortable';
import ReactQuill from 'react-quill';
import { RsvpFormComponent } from 'types';
import { Button, Modal } from 'react-bootstrap';
import { batch } from 'react-redux';
import PaddingOptions from './settingsComponents/padding';
import MarginOptions from './settingsComponents/margin';

type PropsTypes = {
	component: RsvpFormComponent
	updateComponent: (component: RsvpFormComponent) => void
	duplicateComponent: (component: RsvpFormComponent) => void
	deleteComponent: (component: RsvpFormComponent) => void
}

const modules = {
	toolbar: [
		[
			{ 'font': [] }, { 'size': [ 'small', false, 'large', ] },
			'bold', 'italic', 'underline', 'strike', 
		],[
			{ 'align': [] }, { 'color': [] },
			{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' },
			'link', 'clean'
		],
	],
}

const formats = [
	'font', 'size',
	'bold', 'italic', 'underline', 'strike', 
	'align', 'color', 'list', 'bullet', 'indent',
	'link'
]

const ParagraphComponent = ({
	component,
	updateComponent,
	duplicateComponent,
	deleteComponent,
}: PropsTypes) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({id: component.id});

	const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
	const [data, setData] = useState<string>(component.data ? component.data : '<p>Paragraph text</p>')
	const [styles, setStyles] = useState<React.CSSProperties>(component.styles || {
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: 0,
		marginBottom: 0,
		marginLeft: 0,
		marginRight: 0,
		//TODO: add options for background color with transparent and border-radius
	})
	const [options, setOptions] = useState<any>(component.options || {
		allSidePadding: true,
		allSideMargin: true,
	})

	const style = transform ? {
		transform: `translate(${transform.x}px, ${transform.y}px)`,
		transition,
	} : undefined;

	useEffect(() => {

		updateComponent({
			...component,
			data: data,
			styles: styles,
			options: options,
		})

	}, [data, styles, options])


	return (
		<div
			className="form-component paragraph-component"
			data-id={component.id}
			data-type={component.type}
			ref={setNodeRef} 
			style={style} 
			{...attributes} 
		>
			<ReactQuill theme="bubble" modules={modules} formats={formats} value={data} onChange={setData} style={styles} />


			<div className='form-move-component-button' {...listeners}>
				<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
					<path fill="#fff" d="m19.993 9.696-2.764-2.752a.668.668 0 0 0-.965 0 .653.653 0 0 0-.205.476v1.389h-4.14V4.656h1.375a.69.69 0 0 0 .489-.193.668.668 0 0 0 .206-.489.668.668 0 0 0-.206-.488L11.019.72a.668.668 0 0 0-.489-.205.668.668 0 0 0-.489.205L7.29 3.486a.667.667 0 0 0-.206.488c0 .189.069.352.206.489a.675.675 0 0 0 .476.193h1.388v4.153H5.001V7.42a.653.653 0 0 0-.205-.476.626.626 0 0 0-.476-.205.667.667 0 0 0-.489.205L1.067 9.696a.667.667 0 0 0-.206.488c0 .189.069.352.206.489l2.764 2.764c.138.137.3.206.489.206a.626.626 0 0 0 .476-.206A.667.667 0 0 0 5 12.95v-1.376h4.153v4.14H7.766a.653.653 0 0 0-.476.206.668.668 0 0 0 0 .964l2.751 2.764c.138.137.3.206.489.206a.667.667 0 0 0 .489-.206l2.764-2.764a.668.668 0 0 0 0-.964.667.667 0 0 0-.489-.206H11.92v-4.14h4.14v1.376c0 .188.068.351.205.488a.667.667 0 0 0 .964 0l2.765-2.764a.668.668 0 0 0 .206-.489.668.668 0 0 0-.206-.488Z"></path>
				</svg>
			</div>

			<div className="form-action-buttons">
				<Button type='button' variant='primary' size='sm' onClick={() => setShowEditPopup(true)}>
					<svg width="18px" height="18px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
						<path d="M13.6 4L16 6.4M4 16L4.76584 13.1919C4.81581 13.0087 4.84079 12.9171 4.87914 12.8317C4.91319 12.7558 4.95503 12.6837 5.00399 12.6166C5.05913 12.5409 5.12627 12.4737 5.26054 12.3395L11.4606 6.13941C11.5794 6.02061 11.6388 5.9612 11.7073 5.93895C11.7675 5.91937 11.8325 5.91937 11.8927 5.93895C11.9612 5.9612 12.0206 6.02061 12.1394 6.13941L13.8606 7.86059C13.9794 7.97939 14.0388 8.0388 14.0611 8.1073C14.0806 8.16755 14.0806 8.23245 14.0611 8.29271C14.0388 8.3612 13.9794 8.42061 13.8606 8.53941L7.66054 14.7395C7.52627 14.8737 7.45913 14.9409 7.38345 14.996C7.31625 15.045 7.24415 15.0868 7.16831 15.1209C7.08289 15.1592 6.99129 15.1842 6.80808 15.2342L4 16Z"
							stroke="#FFFFFF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
					</svg>
				</Button>
				<Button type='button' variant='primary' size='sm' onClick={() => duplicateComponent(component)}>
					<svg width="18px" height="18px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" >
						<path fill="#FFFFFF" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z" />
						<path fill="#FFFFFF" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z" />
					</svg>
				</Button>
				<Button type='button' variant='danger' size='sm' onClick={() => deleteComponent(component)}>
					<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
						<path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
							stroke="#FFFFFF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
						/>
					</svg>
				</Button>
			</div>

			{(showEditPopup) ? (
				<Modal show={showEditPopup} className="createRsvpFormModal" onHide={() => setShowEditPopup(false)} aria-labelledby="contained-modal-title-vcenter" centered>
					<Modal.Header closeButton>
						<Modal.Title>
							<h5 className='m-0'>Paragraph Settings</h5>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className='small' style={{backgroundColor: '#f9fafb'}}>
						<MarginOptions 
							styles={styles} 
							options={options} 
							onUpdate={(compStyles, compOptions) => {
								batch(() => {
									setStyles({...styles, ...compStyles})
									setOptions({...options, ...compOptions})
								})
							}} 
						/>
						<hr />
						<PaddingOptions 
							styles={styles} 
							options={options} 
							onUpdate={(compStyles, compOptions) => {
								batch(() => {
									setStyles({...styles, ...compStyles})
									setOptions({...options, ...compOptions})
								})
							}} 
						/>
					</Modal.Body>
				</Modal>
			) : null}
		</div>
	);
}

export default ParagraphComponent;