import React, { useEffect, useState } from 'react';
import {useDraggable} from '@dnd-kit/core';
import { RsvpFormComponent } from 'types';

type PropsTypes = {
	component: any
	formComponents: RsvpFormComponent[]
}

const singleFields = ['BUTTON'];

const SidebarComponent = ({
	component,
	formComponents,
}: PropsTypes) => {
	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: component.id,
	});
	
	const style = transform ? {
		transform: `translate(${transform.x}px, ${transform.y}px)`,
		zIndex: 9999,
	} : undefined;


	// check if single components already inserted in form
	if (singleFields.includes(component.id)) {
		const check = formComponents.find(f => singleFields.includes(f.type));
		if (check) {
			return null;
		}
	}

	// check if data-field already inserted in form
	else if (component.group === 'DATA_FIELD' && component.field && formComponents.length) {
		// check if same field already inserted in form
		const check = formComponents.find(f => f.type === 'DATA_FIELD' && f.options.field == component.field);
		if (check) {
			return null;
		}
	}

	return (
		<div 
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			style={style}
			className="component" 
			data-id={component.id}
		>
			<div className="icon">{component.icon}</div>
			<div className="name">{component.name}</div>
		</div>
	)
}

export default SidebarComponent;