import React, { useEffect, useRef, useState } from 'react';
import { RsvpFormComponent } from 'types';
import { Button, ButtonGroup, Form, Image, InputGroup, Modal, Overlay, Tooltip } from 'react-bootstrap';
import { apiUploadRsvpFormImage } from 'api/rsvpForms';

type PropsTypes = {
	styles: React.CSSProperties
	options: any
	onUpdate: (styles: React.CSSProperties, options: any) => void
}

const AlignOptions = ({
	styles,
	options,
	onUpdate,
}: PropsTypes) => {
	const [componentAlign, setComponentAlign] = useState<string>(options.align || 'CENTER')

	useEffect(() => {
		let mL = '0';
		let mR = '0';
		if (componentAlign === 'LEFT') {
			mR = 'auto';
		}
		else if (componentAlign === 'RIGHT') {
			mL = 'auto';
		}
		else if (componentAlign === 'CENTER') {
			mL = 'auto';
			mR = 'auto';
		}

		onUpdate({
			marginLeft: mL,
			marginRight: mR,
		}, {
			align: componentAlign
		})
	}, [componentAlign])


	return (
		<>
			<Form.Group className='d-flex justify-content-between align-items-center'>
				<Form.Label className='m-0'>Align</Form.Label>
				<ButtonGroup>
					<Button variant="outline-secondary" size='sm' active={componentAlign === 'LEFT'} onClick={() => setComponentAlign('LEFT')}>Left</Button>
					<Button variant="outline-secondary" size='sm' active={componentAlign === 'CENTER'} onClick={() => setComponentAlign('CENTER')}>Center</Button>
					<Button variant="outline-secondary" size='sm' active={componentAlign === 'RIGHT'} onClick={() => setComponentAlign('RIGHT')}>Right</Button>
				</ButtonGroup>
			</Form.Group>
		</>
	)
}

export default AlignOptions;