import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { EmailTemplate } from 'types';
import { setHeaderTitle } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { apiUploadTemplate } from 'api/emailTemplate';
import TemplatePlaceholderPopup from './TemplatePlaceholderPopup';


const EmailTemplateEditHTML = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { state }: { state: EmailTemplate | null } = useLocation()
	const { templateId } = useParams();
	const [errMsg, setErrMsg] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [placeholderPopupOpen, setPlaceholderPopupOpen] = useState<boolean>(false);
	const activeEvent = useAppSelector(state => state.events.activeEvent);
	const fileRef = useRef<HTMLInputElement | null>(null);

	console.log({ templateId, state })

	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Email Templates'))
		})
	}, [])

	const handleDrop = (event: any) => {
		event.preventDefault();
		const droppedFiles = event.dataTransfer.files;
		if (droppedFiles.length > 0) {
			console.log({ droppedFiles })
			onFileChange(droppedFiles[0])
		}
	};

	const onFileChange = async (file: File) => {
		if (activeEvent && templateId && file) {
			console.log({ file })
			batch(() => {
				setErrMsg('')
				setIsLoading(true);
			})
			const response = await apiUploadTemplate(activeEvent?.id, templateId, file)
			setIsLoading(false);
			if (response.success) {
				return navigate(`/email-templates/${templateId}/preview`)
			} else {
				setErrMsg(response.message)
			}
		}
	}

	if (!activeEvent) {
		return <Navigate to="/" />
	}

	return (
		<>
			<div className="page-guest-list-view">
				<div className="page-card card">
					<div className="card-header bg-white p-3">
						<div className='d-flex align-items-end justify-content-between'>
							<div>
								<p className='text-muted m-0'>Email template name</p>
								<h5 className='m-0'>{state?.name}</h5>
							</div>
							<div>
								<Button 
									type='button' 
									variant='link' 
									className='text-dark'
									onClick={() => setPlaceholderPopupOpen(true)}
								>View Placeholder</Button>
							</div>
						</div>
					</div>
					<div className="card-body">
						{errMsg && <div className="alert alert-danger">{errMsg}</div>}
						<div className="drop-container"
							style={{
								display: 'flex',
								minHeight: 500,
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
								border: '1px dashed #ccc'
							}}
							onDrop={handleDrop}
							onDragOver={(event) => event.preventDefault()}
						>
							<h4 style={{ color: '#AAA' }}>Upload HTML</h4>

							<div className='mt-4 mb-3'>
								<svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" />
									<path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</div>

							<p>Drag and Drop File</p>
							<p className='small text-muted'>or</p>

							<input type='file' name='file' ref={fileRef} style={{ display: 'none' }} accept='.html, .HTML'
								onChange={e => {
									const files = e.target.files
									// console.log("files:", files)
									if (files && files.length > 0) {
										onFileChange(files[0])
									}
								}}
							/>
							<Button type='button' variant='dark' disabled={isLoading} onClick={() => fileRef.current?.click()}>Browse</Button>
						</div>
					</div>
				</div>


			</div>

			{(placeholderPopupOpen) && (
				<TemplatePlaceholderPopup eventId={activeEvent.id} isOpen={placeholderPopupOpen} setIsOpen={setPlaceholderPopupOpen} />
			)}
		</>
	)
}

export default EmailTemplateEditHTML;