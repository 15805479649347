import { Domain, DomainEmails, ResponseObject } from "types"
import apiService from "services/ApiService";
import cacheService from "services/CacheService";



export const apiGetDomains = async (): Promise<ResponseObject<Domain[]>> => {

	return await apiService.get(`/domains`);
}

export const apiCreateDomain = async (
	domain: string
): Promise<ResponseObject<Domain>> => {

	return await apiService.post(`/domains/create`, {
		domain,
	});
}

export const apiAuthenticateDomain = async (
	domainId: number
): Promise<ResponseObject<Domain>> => {

	return await apiService.post(`/domains/authenticate/${domainId}`, {});
}

export const apiDeleteDomain = async (
	domainId: number
): Promise<ResponseObject<null>> => {

	return await apiService.delete(`/domains/delete/${domainId}`);
}


export const apiGetDomainSenders = async (
	domainId: number
): Promise<ResponseObject<DomainEmails[]>> => {

	return await apiService.get(`/domains/emails/${domainId}`);
}

export const apiCreateDomainSender = async (
	domainId: number,
	name: string,
	email: string,
): Promise<ResponseObject<DomainEmails>> => {

	return await apiService.post(`/domains/emails/create/${domainId}`, {
		name,
		email,
	});
}


export const apiDeleteDomainSender = async (
	senderId: number
): Promise<ResponseObject<null>> => {

	return await apiService.delete(`/domains/emails/delete/${senderId}`);
}

export const apiResendDomainSenderVerification = async (
	senderId: number
): Promise<ResponseObject<null>> => {

	return await apiService.post(`/domains/emails/verification-resend/${senderId}`, {});
}