import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { EmailTemplate, EmailTemplateStatus, EmailTemplateType, GuestList, RsvpForm } from 'types';
import { ButtonGroup, Form, InputGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { batch } from 'react-redux';
import { apiCreateTemplate, apiSendTestEmails, apiUpdateTemplate } from 'api/emailTemplate';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { apiGetGuestByGuestLists, apiGetGuestsByEvent } from 'api/guests';
import { MultiValue } from 'react-select';
import { apiGetTeam } from 'api/team';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/hooks';
import { RSVP_BASE_URL, RsvpFormTypes } from 'constant';
import { apiGetGuestLists } from 'api/guestLists';
import { apiDownloadGuestFormLinks } from 'api/rsvpForms';

type RsvpLinkPopupProps = {
	eventId: number | string;
	form: RsvpForm
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

const RsvpLinkPopup = ({ eventId, form, isOpen, setIsOpen }: RsvpLinkPopupProps) => {
	const baseFormLink = `${RSVP_BASE_URL}/${form.slug}`;
	const [value, setValue] = useState<string>(baseFormLink);
	const [lists, setLists] = useState<GuestList[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			if (eventId && form && form.type === RsvpFormTypes.PRIVATE) {
				const response = await apiGetGuestLists(eventId as number)

				if (response.success) {
					setLists(response.data.lists)
				}
			}
		})()
	}, [eventId, form])

	const copyLink = () => {
		navigator.clipboard.writeText(baseFormLink)
		setValue('copied')
		setTimeout(() => {
			setValue(baseFormLink)
		}, 1000)
	}

	const downloadFormLinkExcel = async () => {
		if (eventId && form) {
			setLoading(true)
			const response = await apiDownloadGuestFormLinks(eventId, form.id);
			setLoading(false)
			if (response.success) {
				window.open(response.data, '_blank');
			}
		}
	}

	return (
		<>
			<Modal show={isOpen} className="createRsvpFormModal" size='lg' onHide={() => setIsOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='m-0'>RSVP Links</h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>

					<div className='d-flex align-items-center justify-content-center mb-4 mt-3'>
						<ToggleButtonGroup 
							type="radio" 
							name="template-types" 
							defaultValue={form.type} 
						>
							<ToggleButton id="tbg-radio-1" variant='outline-dark' 
								value={RsvpFormTypes.PUBLIC} 
								disabled={true}
								style={{ height: 50, lineHeight: '35px', width: 150, }}
							>
								Public
							</ToggleButton>
							<ToggleButton id="tbg-radio-2" variant='outline-dark' 
								value={RsvpFormTypes.PRIVATE} 
								disabled={true}
								style={{ height: 50, lineHeight: '35px', width: 150, }}
							>
								Private
							</ToggleButton>
						</ToggleButtonGroup>
					</div>

					{(form.type === RsvpFormTypes.PUBLIC) ? (
						<>
							<p className='text-center text-success'>Your event is public. Share your link to collect new responses.</p>
							<Row>
								<Col md={8}>
									<Form.Group className='mb-3'>
										<Form.Label>Public Form Link:</Form.Label>
										<InputGroup>
											<Form.Control
												value={value}
												onChange={() => {}}
												disabled
											/>
											<a href={baseFormLink} target='_blank' rel="noreferrer" className='btn btn-white'>
												<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 48 48">
													<path d="M 35.478516 5.9804688 A 2.0002 2.0002 0 0 0 34.085938 9.4140625 L 35.179688 10.507812 C 23.476587 10.680668 14 20.256715 14 32 A 2.0002 2.0002 0 1 0 18 32 C 18 22.427546 25.627423 14.702715 35.154297 14.517578 L 34.085938 15.585938 A 2.0002 2.0002 0 1 0 36.914062 18.414062 L 41.236328 14.091797 A 2.0002 2.0002 0 0 0 41.228516 10.900391 L 36.914062 6.5859375 A 2.0002 2.0002 0 0 0 35.478516 5.9804688 z M 12.5 6 C 8.9338464 6 6 8.9338464 6 12.5 L 6 35.5 C 6 39.066154 8.9338464 42 12.5 42 L 35.5 42 C 39.066154 42 42 39.066154 42 35.5 L 42 28 A 2.0002 2.0002 0 1 0 38 28 L 38 35.5 C 38 36.903846 36.903846 38 35.5 38 L 12.5 38 C 11.096154 38 10 36.903846 10 35.5 L 10 12.5 C 10 11.096154 11.096154 10 12.5 10 L 20 10 A 2.0002 2.0002 0 1 0 20 6 L 12.5 6 z" 
													fill='#2C292C'></path>
												</svg>
											</a>
											<Button variant="dark" onClick={copyLink} >Copy</Button>
										</InputGroup>
									</Form.Group>
								</Col>
							</Row>
						</>
					) : (
						<>
							<p className='text-center text-danger'>Your event is private. Use Personal Form links to collect responses from your invited guests.</p>
							<Row>
								<Col md={8}>
									<Form.Group className='mb-3'>
										<Form.Label>Personal Form Links:</Form.Label>
										<InputGroup>
										<Form.Select defaultValue={form.guest_list_id} disabled={true}>
											{lists.filter(l => l.id === form.guest_list_id).map((list, index) => {
												return <option key={index} value={(list.id) ? list.id : ''} selected={true}>{list.list_name}</option>
											})}
										</Form.Select>
											
											<Button type='button' variant='dark' onClick={downloadFormLinkExcel} disabled={loading}>Download Excel</Button>
										</InputGroup>
									</Form.Group>
								</Col>
							</Row>
						</>
					)}

				</Modal.Body>
			</Modal>
		</>
	)
}

export default RsvpLinkPopup;