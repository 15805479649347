import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { EmailTemplate, EmailTemplateStatus, EmailTemplateType, RsvpForm } from 'types';
import { ButtonGroup, Form, InputGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { batch } from 'react-redux';
import { apiCreateTemplate, apiSendTestEmails, apiUpdateTemplate } from 'api/emailTemplate';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { apiGetGuestByGuestLists, apiGetGuestsByEvent } from 'api/guests';
import { MultiValue } from 'react-select';
import { apiGetTeam } from 'api/team';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/hooks';
import { RSVP_BASE_URL } from 'constant';

type PreviewAsPopupProps = {
	eventId: number | string;
	listId: number | string;
	form: RsvpForm
	activePage: string
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

const PreviewAsPopup = ({ eventId, listId, form, activePage, isOpen, setIsOpen }: PreviewAsPopupProps) => {
	const baseFormLink = `${RSVP_BASE_URL}/${form.slug}`;
	const [previewGuestId, setPreviewGuestId] = useState<string | number | null>(null);
	const [value, setValue] = useState<string>(baseFormLink);


	const searchGuests = async (inputValue: string) => {
		return new Promise<any[]>(async (resolve) => {
			if (eventId) {
				const response = await apiGetGuestByGuestLists(eventId, listId, {
					page: 1,
					limit: 10,
					orderBy: 'id',
					orderType: 'DESC',
					q: inputValue
				})

				if (response.success) {
					resolve([...response.data.items.map(guest => {
						let name = (guest.guest_name) ? guest.guest_name : `${guest.first_name || ''} ${guest.last_name || ''}`;
						return {
							value: guest.unique_guest_id,
							label: `${name} (${(guest.email) ? guest.email : 'N/A'})`
						}
					})])
				}
			}
			else {
				resolve([])
			}
		});
	}

	const handleSubmit = async () => {
		if (previewGuestId === null) {
			return;
		}

		let page = '';
		if (activePage !== 'FORM_PAGE') {
			page = `?${new URLSearchParams({
				page: activePage
			})}`
		}

		const formLink = `${baseFormLink}/${previewGuestId}${page}`

		// console.log('preview', previewGuestId, form.slug, activePage, formLink)
		window.open(formLink, '_blank');
	}

	const copyLink = () => {
		navigator.clipboard.writeText(baseFormLink)
		setValue('copied')
		setTimeout(() => {
			setValue(baseFormLink)
		}, 1000)
	}

	return (
		<>
			<Modal show={isOpen} className="createRsvpFormModal" onHide={() => setIsOpen(false)} aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className='m-0'>Preview as</h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>

					{(form.type === 'PUBLIC') ? (
						<Form.Group className='mb-3'>
							<Form.Label>Public Form Link:</Form.Label>
							<InputGroup>
								<Form.Control
									value={value}
									onChange={() => {}}
									disabled
								/>
								<a href={baseFormLink} target='_blank' rel="noreferrer" className='btn btn-white'>
									<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 48 48">
										<path d="M 35.478516 5.9804688 A 2.0002 2.0002 0 0 0 34.085938 9.4140625 L 35.179688 10.507812 C 23.476587 10.680668 14 20.256715 14 32 A 2.0002 2.0002 0 1 0 18 32 C 18 22.427546 25.627423 14.702715 35.154297 14.517578 L 34.085938 15.585938 A 2.0002 2.0002 0 1 0 36.914062 18.414062 L 41.236328 14.091797 A 2.0002 2.0002 0 0 0 41.228516 10.900391 L 36.914062 6.5859375 A 2.0002 2.0002 0 0 0 35.478516 5.9804688 z M 12.5 6 C 8.9338464 6 6 8.9338464 6 12.5 L 6 35.5 C 6 39.066154 8.9338464 42 12.5 42 L 35.5 42 C 39.066154 42 42 39.066154 42 35.5 L 42 28 A 2.0002 2.0002 0 1 0 38 28 L 38 35.5 C 38 36.903846 36.903846 38 35.5 38 L 12.5 38 C 11.096154 38 10 36.903846 10 35.5 L 10 12.5 C 10 11.096154 11.096154 10 12.5 10 L 20 10 A 2.0002 2.0002 0 1 0 20 6 L 12.5 6 z" 
										fill='#2C292C'></path>
									</svg>
								</a>
								<Button variant="dark" onClick={copyLink} >Copy</Button>
							</InputGroup>
						</Form.Group>
					) : null}

					<Form.Group className="" controlId="selectGuest">
						<Form.Label>Select Guest:</Form.Label>
						<p className='text-muted small'>You can preview personalized form as guest. The guest will see the same form.</p>
						<InputGroup className="">
							<div style={{ flex: 1 }}>
								{(previewGuestId === null) ? (
									<AsyncSelect
										cacheOptions
										defaultOptions
										value={null}
										loadOptions={searchGuests}
										onChange={e => {
											setPreviewGuestId(e.value)
										}}
									/>
								) : (
									<AsyncSelect
										cacheOptions
										defaultOptions
										loadOptions={searchGuests}
										onChange={e => {
											setPreviewGuestId(e.value)
										}}
									/>
								)}
							</div>

							{(previewGuestId !== null) ? (
								<Button variant="outline-secondary" onClick={() => setPreviewGuestId(null)}>
									Cancel
								</Button>
							) : null}
						</InputGroup>

					</Form.Group>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => setIsOpen(false)} style={{ width: 'auto', flexGrow: 1, padding: '1rem' }}>
						Cancel
					</Button>

					<Button variant="dark" onClick={handleSubmit} style={{ width: 'auto', flexGrow: 1.8, padding: '1rem' }} disabled={previewGuestId === null}>
						<div className='d-flex align-items-center justify-content-between'>
							<span>Preview</span>
							<span className="arrow">
								<svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
								</svg>
							</span>
						</div>
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default PreviewAsPopup;