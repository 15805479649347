import { Routes, Route } from 'react-router-dom';

import AuthLayout from 'layout/AuthLayout';

import Login from 'views/Auth/Login';
import Register from 'views/Auth/Register';
import ForgotPassword from 'views/Auth/ForgotPassword';
import NotFound from 'views/NotFound';
import ResetPassword from 'views/Auth/ResetPassword';
import AcceptInvitation from 'views/Auth/AcceptInvitation';
import ActivateAccount from 'views/Auth/ActivateAccount';
import DomainSenderEmailSuccess from 'views/Auth/domain/DomainSenderEmailSuccess';
import DomainSenderEmailError from 'views/Auth/domain/DomainSenderEmailError';

const AuthRoutes = (): JSX.Element => {

	return (
		<Routes>
			<Route path="/" element={<AuthLayout />}>
				<Route element={<Login />} path="/" />
				<Route element={<Login />} path="/login" />
				<Route element={<Login />} path="/logout" />
				<Route element={<Register />} path="/register" />
				<Route element={<ActivateAccount />} path="/activate-account/:token" />
				<Route element={<ForgotPassword />} path="/forgot-password" />
				<Route element={<ResetPassword />} path="/reset-password/:token" />
				<Route element={<AcceptInvitation />} path="/accept-invitation/:token" />
				<Route element={<DomainSenderEmailSuccess />} path="/domain-sender-success" />
				<Route element={<DomainSenderEmailError />} path="/domain-sender-error" />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	)
}

export default AuthRoutes;