import React, { useEffect, useRef, useState } from 'react';
import { Event, RsvpFormComponent, RsvpFormPageStyles } from 'types';
import { Badge, Button, ButtonGroup, Col, Form, InputGroup, Modal, Overlay, Row, Tooltip } from 'react-bootstrap';
import { apiSaveRsvpFormPageComponents } from 'api/rsvpForms';
import AlignOptions from './components/settingsComponents/align';
import { batch } from 'react-redux';

type PropsTypes = {
	activeEvent: Event
	formId: string | undefined
	activePage: string
	pageStyles: RsvpFormPageStyles
	setPageStyles: React.Dispatch<React.SetStateAction<RsvpFormPageStyles>>
	setOpenThemeSidebar: React.Dispatch<React.SetStateAction<boolean>>
}

const ThemeSidebar = ({
	activeEvent,
	formId,
	activePage,
	pageStyles,
	setPageStyles,
	setOpenThemeSidebar
}: PropsTypes) => {
	const contentAreaRange = useRef(null);
	const contentPaddingRange = useRef(null);
	const [containerAlign, setContainerAlign] = useState<string>('CENTER')

	// console.log({ pageStyles })

	return (
		<div className='form-sidebar p-3'>
			<div className='d-flex justify-content-between align-items-center'>
				<h4 className='form-sidebar-heading'>General</h4>
				<Button type='button' variant='white' size='sm' onClick={() => setOpenThemeSidebar(false)}>Back</Button>
			</div>


			<div className="small">
				<Form.Group>
					<Form.Label>Content area width</Form.Label>
					<Form.Range
						ref={contentAreaRange}
						min="400" max="1000" step="10" value={pageStyles.container.maxWidth}
						onChange={e => setPageStyles({ ...pageStyles, container: { ...pageStyles.container, maxWidth: parseInt(e.target.value) } })}
					/>
					<Overlay target={contentAreaRange.current} show={true} placement="top">
						{(props) => (<Tooltip {...props}>{pageStyles.container.maxWidth}px</Tooltip>)}
					</Overlay>
				</Form.Group>
				<hr />
				<Form.Group>
					<Form.Label>Content area padding</Form.Label>
					<Form.Range
						ref={contentPaddingRange}
						min="0" max="50" step="5" value={pageStyles.container.padding}
						onChange={e => setPageStyles({ ...pageStyles, container: { ...pageStyles.container, padding: parseInt(e.target.value) } })}
					/>
					<Overlay target={contentPaddingRange.current} show={true} placement="top">
						{(props) => (<Tooltip {...props}>{pageStyles.container.padding}px</Tooltip>)}
					</Overlay>
				</Form.Group>
				<hr />
				<AlignOptions 
					styles={pageStyles.container} 
					options={{
						align: containerAlign
					}} 
					onUpdate={(compStyles, compOptions) => {
						batch(() => {
							setPageStyles({
								...pageStyles, container: {
									...pageStyles.container,
									...compStyles
								}
							})
							setContainerAlign(compOptions.align)
						})
					}} 
				/>
				<hr />
				<Form.Group className='d-flex justify-content-between align-items-center'>
					<Form.Label className='m-0'>Background color</Form.Label>
					<InputGroup style={{ width: 125 }}>
						<Form.Control
							type="color"
							value={pageStyles.body.background}
							title="Background color"
							style={{ width: 40 }}
							onChange={e => setPageStyles({ ...pageStyles, body: { ...pageStyles.body, background: e.target.value } })}
						/>
						<InputGroup.Text style={{ fontSize: '0.8rem', width: 'calc(100% - 40px)' }}>{pageStyles.body.background}</InputGroup.Text>
					</InputGroup>
				</Form.Group>
				<hr />
				<Form.Group className='d-flex justify-content-between align-items-center'>
					<Form.Label className='m-0'>Content area background color</Form.Label>
					<InputGroup style={{ width: 125 }}>
						<Form.Control
							type="color"
							value={pageStyles.container.background}
							title="Content area background color"
							style={{ width: 40 }}
							onChange={e => setPageStyles({ ...pageStyles, container: { ...pageStyles.container, background: e.target.value } })}
						/>
						<InputGroup.Text style={{ fontSize: '0.8rem', width: 'calc(100% - 40px)' }}>{pageStyles.container.background}</InputGroup.Text>
					</InputGroup>
				</Form.Group>
				<hr />
				<Form.Group>
					<Form.Label className=''>Field Labels</Form.Label>
					<Row>
						<Col>
							<Form.Select 
								value={pageStyles.fieldLabels?.fontFamily || 'Arial'}
								onChange={e => setPageStyles({ ...pageStyles, fieldLabels: { ...pageStyles.fieldLabels, fontFamily: e.target.value } })}
							>
								<option value='Arial, sans-serif'>Arial</option>
								<option value='Helvetica, sans-serif'>Helvetica</option>
								<option value='Arial Black, sans-serif'>Arial Black</option>
								<option value='Arial Narrow, sans-serif'>Arial Narrow</option>
								<option value='Comic Sans MS, cursive, sans-serif'>Comic Sans MS</option>
								<option value='Times New Roman, serif'>Times New Roman</option>
								<option value='Courier New, sans-serif'>Courier New</option>
								<option value='Georgia, serif'>Georgia</option>
							</Form.Select>
						</Col>
						<Col>
							<Form.Select 
								value={pageStyles.fieldLabels?.fontSize || '16px'}
								onChange={e => setPageStyles({ ...pageStyles, fieldLabels: { ...pageStyles.fieldLabels, fontSize: e.target.value } })}
							>
								{[12, 14, 16, 18, 20, 22, 24, 26, 28, 30].map(s => {
									return (<option value={`${s}px`}>{s}px</option>)
								})}
							</Form.Select>
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col></Col>
						<Col>
							<InputGroup style={{ width: 125, marginLeft: 'auto' }}>
								<Form.Control
									type="color"
									value={pageStyles.fieldLabels?.color || '#000000'}
									title="Field label color"
									style={{ width: 40 }}
									onChange={e => setPageStyles({ ...pageStyles, fieldLabels: { ...pageStyles.fieldLabels, color: e.target.value } })}
								/>
								<InputGroup.Text style={{ fontSize: '0.8rem', width: 'calc(100% - 40px)' }}>{pageStyles.fieldLabels?.color || '#000000'}</InputGroup.Text>
							</InputGroup>
						</Col>
					</Row>
				</Form.Group>
			</div>
		</div>
	)
}

export default ThemeSidebar;